import React from "react";

function Solution() {
  return (
    <>
      <section className="solution pt-5 pb-5">
        <div className="container solu">
          <div className="soluhead">
            <h2>ABOUT US:</h2>
          </div>
          <p>
            At Agile Tech Labs, we harness the power of technology to redefine
            how people achieve their goals. Our mission is to enable individuals
            and organizations to reach new heights by providing innovative
            solutions that make the impossible possible. Since 2003, we have
            been at the forefront of technological advancements, delivering
            scalable and cutting-edge solutions tailored to the unique needs of
            our clients.
          </p>
          <br />
          <p>
            Our team of seasoned architects, program managers, and business
            experts bring over 15 years of experience in their respective
            fields, ensuring that every project we undertake is executed with
            precision and excellence. We specialize in modernizing legacy
            systems and migrating applications to newer technologies, helping
            our clients stay ahead in an ever-evolving digital landscape.
          </p><br/>
          <p>
            At Agile Tech Labs, we are committed to delivering right-fit
            technology solutions that are not only easy to maintain but also
            significantly lower maintenance expenses, reducing overall costs.
            Our matured delivery processes and repository of reusable components
            guarantee on-time, high-quality deliveries that exceed expectations.
          </p>
          <br />
          <p>
            Join us as we continue to push the boundaries of what technology can
            do and help you achieve the extraordinary.
          </p>
        </div>
      </section>
    </>
  );
}

export default Solution;
