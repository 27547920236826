import React, { useState } from "react";
import cost from "../../images/cost2.png";
import improve from "../../images/improov2.png";
import enhanced from "../../images/enhanced1.png";
import product from "../../images/product1.png";
import scaleblity from "../../images/scaleblity1.png";
import compliance from "../../images/compliance1.png";
import satisfaction from "../../images/stisfaction1.png";

function Benifitrpa() {
  return (
    <>
      <section className="benifit-rpa pb-5">
        <div className="container">
          <h2>Benefits of Our RPA Solutions:</h2>
          <div className=" row mt-5 w-100 d-flex flex-wrap benifit-solution">
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={cost} alt="cost" />
                <h3>Cost Efficiency:</h3>
              </div>
              <p>
                Prepare to be amazed as our automated tasks reduce operational
                costs and skyrocket productivity to new heights!
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={improve} alt="improve" />
                <h3>Improved Accuracy:</h3>
              </div>
              <p>
                Say goodbye to errors and welcome enhanced data quality with
                open arms, thanks to the incredible precision of our RPA bots.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={enhanced} alt="enhanced" />
                <h3>Enhanced Productivity:</h3>
              </div>
              <p>
                Brace yourself for a whole new level of productivity as our RPA
                solutions liberate your employees, allowing them to focus on
                strategic tasks that will propel your business forward.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={scaleblity} alt="scaleblity" />
                <h3>Scalability:</h3>
              </div>
              <p>
                Get ready to effortlessly adapt to changing business needs as
                our automation efforts can be easily scaled to meet any
                challenge that comes your way.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={product} alt="product" />
                <h3>Faster Processing:</h3>
              </div>
              <p>
                Hold onto your hats as our RPA solutions accelerate task
                completion times, ensuring lightning-fast efficiency that will
                leave you breathless.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={compliance} alt="compliance" />
                <h3>Improved Compliance:</h3>
              </div>
              <p>
                Rest easy knowing that our RPA solutions guarantee compliance
                with regulations and policies through consistent, auditable
                processes that will make any auditor's heart skip a beat.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={satisfaction} alt="satisfaction" />
                <h3>Customer Satisfaction:</h3>
              </div>
              <p>
                Prepare to dazzle your customers with unparalleled service as
                our RPA solutions reduce errors and processing times, leaving
                them smiling from ear to ear.
              </p>
            </div>
            <p className="mt-3">
              Get ready to embark on an exciting journey with our RPA solutions
              that will transform the way you do business. Say goodbye to
              monotonous tasks and hello to a future filled with innovation,
              efficiency, and success. Let us take your business to new heights
              with the power of RPA!
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Benifitrpa;
