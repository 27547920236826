import React from "react";
import cost from "../../images/functionaltexting.png";
import improve from "../../images/nonfunctional.png";
import enhanced from "../../images/automationtesting.png";
import product from "../../images/mobiletesting.png";
import scaleblity from "../../images/securitytesting.png";
import compliance from "../../images/performancetesting.png";
import satisfaction from "../../images/compatiblity.png";
import regrasion from "../../images/regrasion.png";
import user from "../../images/user.png";
import complition from "../../images/complition.png";


function Benifitofmts() {

  return (
    <>
      <section className="mtsbenifit pb-5">
        <div className="container">
          <h2>Benefits of Managed Testing Services (MTS):</h2>
          <p>
            Agile Tech Labs offers Managed Testing Services (MTS) that cover a
            wide range of essential areas to guarantee thorough testing for
            software applications. These areas include:
          </p>
          <div className=" row mt-5 w-100 d-flex flex-wrap benifit-solution">
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={cost} alt="cost" />
                <h3>Functional Testing:</h3>
              </div>
              <p>
                Making sure the software operates correctly based on specified
                requirements, ensuring all features function as intended.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={improve} alt="improve" />
                <h3>Non-Functional Testing:</h3>
              </div>
              <p>
                Concentrating on performance, usability, reliability, and
                scalability to ensure the software meets necessary benchmarks
                and user expectations.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={enhanced} alt="enhanced" />
                <h3>Automation Testing:</h3>
              </div>
              <p>
                Utilizing tools and scripts to automate testing, streamlining
                processes such as repetitive tests, regression tests, and
                performance tests.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={product} alt="product" />
                <h3>Mobile Testing:</h3>
              </div>
              <p>
                Ensuring mobile applications function properly across various
                devices, screen sizes, and operating systems, reflecting the
                widespread use of mobile devices.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={scaleblity} alt="scaleblity" />
                <h3>Security Testing:</h3>
              </div>
              <p>
                Identifying and addressing security vulnerabilities to safeguard
                against potential threats and attacks.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={compliance} alt="compliance" />
                <h3>Performance Testing:</h3>
              </div>
              <p>
                Assessing software performance under different conditions to
                pinpoint and resolve performance bottlenecks for optimal
                functionality.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={satisfaction} alt="satisfaction" />
                <h3>Compatibility Testing:</h3>
              </div>
              <p>
                Confirming software compatibility with diverse hardware,
                operating systems, browsers, and devices for seamless operation
                across different environments.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={regrasion} alt="regrasion" />
                <h3>Regression Testing:</h3>
              </div>
              <p>
                Re-testing software post-changes to uphold integrity and
                stability, ensuring existing functionalities remain unaffected.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={user} alt="user" />
                <h3>User Acceptance Testing (UAT):</h3>
              </div>
              <p>
                Testing software with end-users to validate it meets their
                requirements and expectations before launch.
              </p>
            </div>
            <div className="benifit-sol">
              <div className="d-flex align-items-center">
                <img src={complition} alt="complition" />
                <h3>Compliance Testing:</h3>
              </div>
              <p>
                Ensuring software adheres to industry standards, regulations,
                and legal requirements.
              </p>
            </div>
            <p className="mt-3">
              Agile Tech Labs prioritizes these areas to ensure efficient
              testing, ultimately enhancing software quality, reliability, and
              performance. Let us help you elevate your software to new heights!
            </p>
          </div>
        </div>
      </section>      
    </>
  );
}

export default Benifitofmts;
