import React from "react";
import Modal from "react-bootstrap/Modal";
function Infasetup({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>RPA Infrastructure setup</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          When embarking on RPA deployment, our first step is to assess your
          existing IT infrastructure to ensure compatibility and scalability. We
          establish separate environments for development, testing, and
          production to uphold security and reliability standards. Robust
          security measures and compliance protocols are implemented to
          safeguard sensitive data and ensure regulatory adherence. We
          seamlessly integrate RPA tools with your enterprise systems, including
          ERP, CRM, and legacy applications, to enable efficient data exchange
          and process automation. Leveraging APIs, web services, and connectors
          provided by RPA vendors, we establish integration points for seamless
          communication between bots and enterprise systems. Continuously
          monitoring performance metrics, we optimize infrastructure components
          to enhance the efficiency and reliability of RPA operations, ensuring
          optimal outcomes and ROI
        </p>
        <br />

        <ol>
          <li>
            Comprehensive assessment of existing IT infrastructure for
            compatibility and scalability
          </li>
          <li>
            Establishment of separate environments for development, testing, and
            production
          </li>
          <li>
            Implementation of robust security measures and compliance protocols
          </li>
          <li>
            Seamless integration of RPA tools with enterprise systems using APIs
            and connectors
          </li>
          <li>
            Continuous monitoring and optimization of infrastructure components
            for enhanced efficiency and reliability
          </li>
        </ol>
      </Modal>
    </>
  );
}

export default Infasetup;
