import React from "react";
import { Accordion } from "react-bootstrap";

function TransformFaq() {
  return (
    <>
      <section className="rpafaq pt-5 pb-5">
        <div className="container">
          <div className="row mt-5">
            <h2 className="mb-5">
              Frequently Asked{" "}
              <span style={{ color: "#003eae" }}>Questions</span> about Agile
              Transformation
            </h2>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What is Agile Transformation Services?
                </Accordion.Header>
                <Accordion.Body>
                  Agile transformation services give organizations the tools,
                  training, and support they need to adopt Agile methodologies
                  successfully.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Why is Agile Transformation important?
                </Accordion.Header>
                <Accordion.Body>
                  The importance of Agile Transformation cannot be overstated.
                  It is crucial for organizations to become more adaptive,
                  responsive, and innovative in today's fast-paced business
                  environment. By embracing Agile Transformation, organizations
                  can deliver value to customers more quickly and effectively.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What are the key benefits of Agile Transformation?
                </Accordion.Header>
                <Accordion.Body>
                  The benefits of Agile Transformation are numerous and
                  impactful. They include improved adaptability, enhanced
                  collaboration, increased productivity, better quality
                  products, faster time-to- market, and higher customer
                  satisfaction.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What are the common challenges of Agile Transformation?
                </Accordion.Header>
                <Accordion.Body>
                  Agile Transformation does come with its challenges. Common
                  obstacles include resistance to change, lack of understanding
                  of agile principles, inadequate training and coaching,
                  difficulty in aligning teams and processes, and cultural
                  barriers.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What are the stages of agile transformation?
                </Accordion.Header>
                <Accordion.Body>
                  Agile transformation typically involves several stages:
                  <br />
                  <li>
                    <strong>Assessment and Planning:</strong> Evaluate the
                    current state of the organization, identify goals for Agile
                    adoption, and create a transformation roadmap.
                  </li>
                  <li>
                    <strong>Training and Education:</strong> Educate teams and
                    leaders on Agile principles, practices, and methodologies
                    through workshops and training sessions.
                  </li>
                  <li>
                    <strong>Pilot Projects:</strong> Implement Agile practices
                    on a small scale to test and refine processes before broader
                    adoption.
                  </li>
                  <li>
                    <strong>Scaling Agile:</strong> Expand Agile practices
                    across more teams and departments, adapting processes as
                    needed for different areas of the organization.
                  </li>
                  <li>
                    <strong> Continuous Improvement:</strong> Foster a culture
                    of continuous learning and adaptation, regularly assessing
                    and refining Agile practices to improve efficiency and
                    effectiveness.
                  </li>
                  <li>
                    <strong> Organizational Alignment:</strong> Ensure alignment
                    between Agile teams and the broader organizational goals and
                    structures, promoting collaboration and transparency.
                  </li>
                  <li>
                    <strong>Sustaining Agility:</strong> Maintain and support
                    Agile practices over time, integrating Agile into the
                    organization's culture and strategic planning.
                  </li>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What role does leadership play in Agile Transformation?
                </Accordion.Header>
                <Accordion.Body>
                  Leadership plays a crucial role in Agile Transformation.
                  Leaders must provide vision, direction, and support. They need
                  to champion agile principles, empower teams, remove obstacles,
                  and lead by example. With strong leadership, organizations can
                  successfully navigate the exciting journey of Agile
                  Transformation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  How can Agile Tech Labs help with Agile Transformation?
                </Accordion.Header>
                <Accordion.Body>
                  The team of experts at Agile Tech Labs is here to help
                  organizations with the knowledge, strategies, and support you
                  need to succeed. From customized plans to coaching and
                  training services, we have everything you need to make your
                  transformation a success. Let's work together to make your
                  organization more agile and efficient than ever before!
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default TransformFaq;
