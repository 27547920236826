import React from "react";
import Modal from "react-bootstrap/Modal";
function Project({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Test Project Augmentation (a-TPA)</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p>
          Agile Techlabs’ testing experts will work in collaboration with our
          customers to deliver successful testing and quality assurance
          activities.
        </p>
      </Modal>
    </>
  );
}

export default Project;
