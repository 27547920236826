import React from "react";

function Focuscontact() {
  return (
    <>
      <section className="Rpacontact">
        <div className="container h-100">
          <div className="focusform">
            <h2>Contact us today to learn more!</h2>
            <p>
              Are you ready to catapult your business to new heights with
              Robotics Process Automation (RPA)? Get in touch with us right away
              <br /> to schedule a consultation and unlock the potential of our
              tailor- made RPA solutions. Brace yourself for a revolutionary
              transformation <br />
              of your operations that will propel your business towards
              unparalleled success!
            </p>
            <div className="d-flex flex-wrap rpainput">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
              <input type="text" placeholder="Business Email" />
              <input type="text" placeholder="Company" />
              <input type="text" placeholder="Business Phone" />
              <select>
                <option>Service</option>
                <option value="Migrate the bots">Migrate the bots</option>
                <option value="Integrate the bots">Integrate the bots</option>
                <option value="Develop the bots">Develop the bots</option>
                <option value="Implement the bots">Implement the bots</option>
                <option value="Free session to understand RPA">
                  Free session to understand RPA
                </option>
              </select>
            </div>
            <div className="send">
              <textarea placeholder="Message" />
              <button>SEND</button>
            </div>
          </div>
        </div>
        <div className="focus-copy">
          <p className="copyright ">
            © 2024 Agile Tech Labs. All Rights Reserved.
          </p>
        </div>
      </section>
    </>
  );
}

export default Focuscontact;
