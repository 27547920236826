import React from "react";
import Modal from "react-bootstrap/Modal";
function Training({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Training and advisory</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We teach mentor, and guide leaders and teams throughout the company to
          adopt Agile principles and practices.
        </p>
      </Modal>
    </>
  );
}

export default Training;
