import React from "react";
import NavbarWhite from "../Home/NavbarWhite";
import Mtsbanner from "../MTS/Mtsbanner";
import Whatismts from "../MTS/Whatismts";
import Benifitofmts from "../MTS/Benifitofmts";
import Whychooseus from "../MTS/Whychooseus";
import Mtsfaq from "../MTS/Mtsfaq";
import Focuscontact from "../RPA/Focuscontact";
import Mtsfocus from "../MTS/Mtsfocus";
import Domain from "../MTS/Domain";
import Offringaproach from "../MTS/Offringaproach";

function MtsRoute() {
  return (
    <>
      <NavbarWhite />
      <Mtsbanner />
      <Whatismts />
      <Benifitofmts />
      <Mtsfocus />
      <Domain />
      <Whychooseus />
      <Offringaproach />
      <Mtsfaq />
      <Focuscontact />
    </>
  );
}

export default MtsRoute;
