import React from 'react'
import Modal from "react-bootstrap/Modal";
function Rpagenai({ show, handleClose }) {  

    return (
      <>
        <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
            <div className="rpafocusmodalhead">
              <h2>RPA Process Discovery using Gen AI</h2>
              <span className="close" onClick={handleClose}>
                &times;
              </span>
            </div>
            <p className="rpafocusmodalpara">
              As an RPA consulting firm, our process discovery capabilities
              using RPA tools leveraging Gen AI enable us to identify, analyze,
              and document existing business processes with precision and
              efficiency. Leveraging RPA tools augmented with Gen AI, we
              meticulously identify, analyze, and document existing business
              processes with unparalleled precision and efficiency. By employing
              advanced automation technologies, we seamlessly capture and
              visualize process flows, pinpoint bottlenecks, and propose
              opportunities for optimization and automation. Our proficiency in
              process discovery equips organizations with the tools they need to
              streamline operations, achieve cost savings, and embark on their
              RPA journey with confidence.
            </p>
            <br />

            <ol>
              <li>
                Enhanced process discovery through Gen AI-powered RPA tools
              </li>
              <li>
                Precise identification, analysis, and documentation of business
                processes
              </li>
              <li>Seamless capture and visualization of process flows</li>
              <li>
                Pinpointing bottlenecks and recommending opportunities for
                optimization
              </li>
          </ol>
          </Modal>
      </>
    );
}

export default Rpagenai