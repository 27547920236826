import React, { useState } from "react";
// import MUIDataTable from "mui-datatables";

function Talentdata() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10;
  const handlePrevClick = () => {
      if (currentPage > 1) {
          setCurrentPage(prevPage => prevPage - 1);
      }
  };

  const handleNextClick = () => {
      if (currentPage < totalPages) {
          setCurrentPage(prevPage => prevPage + 1);
      }
  };

  const handlePageInputChange = e => {
      const inputPage = parseInt(e.target.value);
      if (inputPage >= 1 && inputPage <= totalPages) {
          setCurrentPage(inputPage);
      }
  };
  // const columns = ["Name", "Date", "Time", "By Category", "By Organizer"];
  // const options = {
  //   filterType: "checkbox",
  //   responsive: "vertical",
  //   rowsPerPage: 10,
  //   rowsPerPageOptions: [10, 20, 50],
  //   search: true,
  //   print: false,
  //   download: false,
  //   viewColumns: true,
  //   pagination: true,
  //   serverSide: false,
  //   selectableRows: "none",
  // };

  // const data = [
  //   {
  //     Name: "himanshu",
  //     Date: 212425,
  //     Time: 10,
  //     Category: "vdv",
  //     Organizer: "vfvfv",
  //   },
  //   {
  //     Name: "ggggg",
  //     Date: 212425,
  //     Time: 10,
  //     Category: "vdv",
  //     Organizer: "vfvfv",
  //   },
  //   {
  //     Name: "vfdvdfdf",
  //     Date: 212425,
  //     Time: 10,
  //     Category: "vdv",
  //     Organizer: "vfvfv",
  //   },
  //   {
  //     Name: "grgggrg",
  //     Date: 212425,
  //     Time: 10,
  //     Category: "vdv",
  //     Organizer: "vfvfv",
  //   },
  // ];
  return (
    <>
      <section className="talentdata">
        <div>
          <h6>Talent List</h6>
          {/* <MUIDataTable
            title={"SEMINARS AND EVENTS"}
            data={data}
            columns={columns}
            options={options}
          /> */}
          <table>
            <tr className="tablehead">
              <th className="datano">#</th>
              <th>DATE</th>
              <th>EXPERIENCE</th>
              <th>PRIMARY SKILLS</th>
              <th>SECONDARY SKILLS</th>
              <th>ACTION</th>
              <th>DOWNLOAD</th>
            </tr>
            <tr>
              <td className="datano">1</td>
              <td>September 9, 2013</td>
              <td>5+ Years</td>
              <td>UiPath, Automation Anywhere</td>
              <td>Process Analysis, Python</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="datano">2</td>
              <td>October 25, 2019</td>
              <td>3+ Years</td>
              <td>Blue Prism, Robotic Process Automation</td>
              <td>Data Analytics, Java</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="datano">3</td>
              <td>October 25, 2019</td>
              <td>6+ Years</td>
              <td>Automation Architecture, Process Automation</td>
              <td>Data Integration, Process Mapping</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="datano">4</td>
              <td>May 20, 2015</td>
              <td>5+ Years</td>
              <td>UiPath, Automation Anywhere</td>
              <td>Business Process Analysis, Scripting</td>
              <td></td>
              <td></td>
            </tr>
          </table>
          <div className="pagination">
            <div className="pagi">
              <button onClick={handlePrevClick} disabled={currentPage === 1}>
                &laquo;
              </button>
              <li
                min="1"
                max={totalPages}
                value={currentPage}
                onChange={handlePageInputChange}
              >
                {currentPage}
              </li>
              <button
                onClick={handleNextClick}
                disabled={currentPage === totalPages}
              >
                &raquo;
              </button>
              <span> of {totalPages} Pages</span>
            </div>
            <div className="itemshow">
              <p>
                Showing <span>15</span> items per page
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Talentdata;
