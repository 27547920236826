import React from "react";
import Modal from "react-bootstrap/Modal";
function Staffing({ show, handleClose }) {
  return (
    <>
      <Modal
        className="modal rpafocusmodal"
        show={show}
        onHide={handleClose}
      >
        <div className="rpafocusmodalhead">
          <h2>Permanent Placement</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We help you find full-time employees who are committed to contributing
          to your long-term success.
        </p>
      </Modal>
    </>
  );
}

export default Staffing;
