import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import AdduserModal from "./AdduserModal";
import EditUserModal from "./EditUserModal";
import MUIDataTable from "mui-datatables";
import moment from "moment";

function Dashboard() {
  const [data, setData] = useState([]);
  const [user, setUser] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const openEditModal = (id) => {
    setShowEditModal(true);
    setSelectedId(id);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    setSelectedId("");
  };

  useEffect(() => {
    axios
      .get("https://agile.digiwbs.com/Admin/api/users")
      .then((result) => {
        // console.log("Data fetched:", result.data);
        setData(result.data);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Failed to fetch user data",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, []);

  useEffect(() => {
    if (selectedId) {
      axios
        .get(`https://agile.digiwbs.com/Admin/api/users/${selectedId}`)
        .then((result) => {
          setUser(result.data);
          // console.log("Data fetched by Id:", result.data);
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed to fetch user data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  }, [selectedId]);

  const deleteUser = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://agile.digiwbs.com/Admin/api/users/${userId}`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            Swal.fire("Deleted!", "User has been deleted.", "success");
            // Fetch updated data after deletion
            axios
              .get("https://agile.digiwbs.com/Admin/api/users")
              .then((result) => {
                setData(result.data);
              })
              .catch((err) => {
                console.error("Error fetching user data:", err);
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Failed to fetch updated user data",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          })
          .catch((err) => {
            Swal.fire(
              "Error!",
              err.response?.data?.message || "Failed to delete user.",
              "error"
            );
          });
      }
    });
  };

  const columns = [
    {
      name: "first_name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const lastName = tableMeta.rowData[tableMeta.columnIndex + 1]; // Adjust this index if needed
          const email = tableMeta.rowData[tableMeta.columnIndex + 2];
          return (
            <>
              <div className="d-flex align-items-center">
                <div>{`${value} ${lastName}`}</div>
                {/* <div className="role-btn">{role}</div> */}
              </div>
              <div style={{ color: "gray", fontSize: "0.9em" }}>{email}</div>
            </>
          );
        },
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        display: false, // Hide last name column
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        display: false, // Hide email column
      },
    },
    {
      name: "created_at",
      label: "Create Date",
      options: {
        filter: true,
        sort: true,
        // customBodyRender: (value) => new Date(value).toLocaleDateString(),
        customBodyRender: (value) => {
          moment(value).format("DD MMM, yyyy");
        }, // Adjust date format
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          switch (value) {
            case "superadmin":
              return "Super Admin";
            case "admin":
              return "Admin";
            case "employee":
              return "Employee";
            default:
              return "";
          }
        },
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div className="fa-icon">
            <i
              className="fa fa-pen"
              onClick={() => openEditModal(value)}
              style={{ cursor: "pointer", marginRight: "10px" }}
            ></i>
            <i
              className="fa fa-trash"
              onClick={() => deleteUser(value)}
              style={{ cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "vertical",
    selectableRows: "multiple",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
    search: true,
    print: false,
    download: true,
    viewColumns: true,
    pagination: true,
  };

  return (
    <>
      <section className="dashboard">
        <div className="admin-name">
          <h2>👋 Hey, {user?.first_name}</h2>
          <p>Here is all your Agiletechlabs Dashboard</p>
        </div>
        <div className="user-create">
          <div className="user-sort">
            <button className="btn add-user" onClick={openModal}>
              + Add User
            </button>
          </div>
        </div>
        <div className="user-table">
          <MUIDataTable
            title={"List Users"}
            data={data}
            columns={columns}
            options={options}
          />
        </div>
      </section>
      <AdduserModal show={showModal} handleClose={closeModal} />
      <EditUserModal
        show={showEditModal}
        handleClose={closeEditModal}
        userId={selectedId}
      />
    </>
  );
}

export default Dashboard;
