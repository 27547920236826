import React from 'react'

function Itstaffingbanner() {
  return (
    <>
      <section className="cyberbanner"></section>
    </>
  );
}

export default Itstaffingbanner