import React from "react";
import Digital from "../Home/Digital";
import Inovation from "../Home/Inovation";
import Qaauto from "../Home/Qaauto";
import Robotics from "../Home/Robotics";
import Scrum from "../Home/Scrum";
import Staff from "../Home/Staff";
import ReactPageScroller from "react-page-scroller";

const Slider = () => {
  return (
    <>
      <ReactPageScroller>
        <div className="slide-item">
          <Inovation />
        </div>
        <div className="slide-item">
          <Robotics />
        </div>
        <div className="slide-item">
          <Qaauto />
        </div>
        <div className="slide-item">
          <Scrum />
        </div>
        <div className="slide-item">
          <Staff />
        </div>
        <div className="slide-item">
          <Digital />
        </div>
      </ReactPageScroller>
    </>
  );
};

export default Slider;
