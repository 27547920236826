import React from "react";
import expertise from "../../images/expert1.png";
import record from "../../images/comprasive1.png";
import solution from "../../images/costeffect4.png";
import scalable from "../../images/focusproduct.png";
import cost from "../../images/scal2.png";
import compransive from "../../images/customercenter.png";
import client from "../../images/provenrecord.png";

function Whychooseus() {
  return (
    <>
      <section className="mtschoose pt-5 pb-5">
        <div className="container">
          <h2>
            Why should you choose Agile Tech Labs for your Managed Testing
            Services (MTS)?
          </h2>
          <p>
            Agile Tech Labs stands out as the top choice for Managed Testing
            Services (MTS) for a multitude of reasons:
          </p>
          <div className="row w-100 mts-row">
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={expertise} alt="Expertise" />
                <h3>Expertise</h3>
              </div>
              <p>
                Our team of seasoned testing professionals boasts expertise in a
                wide array of testing domains and technologies. With their
                wealth of experience, you can rest assured that your software
                products will undergo thorough and effective testing.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={record} alt="Comprehensive Services" />
                <h3>Comprehensive Services</h3>
              </div>
              <p>
                At Agile Tech Labs, we offer an extensive range of testing
                services, including functional testing, performance testing,
                security testing, and more. Our all-encompassing approach allows
                you to fulfill all your testing needs with a single, reliable
                service provider.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={solution} alt="Cost-Effectiveness" />
                <h3>Cost-Effectiveness</h3>
              </div>
              <p>
                By outsourcing your testing activities to Agile Tech Labs, you
                can enjoy greater cost-effectiveness compared to maintaining an
                in-house testing team. We provide flexible pricing models,
                enabling you to pay for testing services as required.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={scalable} alt="Focus on Quality" />
                <h3>Focus on Quality</h3>
              </div>
              <p>
                Quality is at the core of everything we do at Agile Tech Labs.
                We leverage the latest testing tools and methodologies to ensure
                that your software meets the highest quality standards.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3>Scalability</h3>
              </div>
              <p>
                Agile Tech Labs can swiftly scale testing resources in line with
                your project requirements. This flexibility empowers you to
                adapt to changing testing needs seamlessly.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={compransive} alt="Customer-Centric Approach" />
                <h3>Customer-Centric Approach</h3>
              </div>
              <p>
                We take a customer-centric approach to our services, ensuring
                that we fully grasp your specific needs and requirements. We
                collaborate closely with you to craft customized testing
                solutions that cater to your unique needs.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={client} alt="Proven Track Record" />
                <h3>Proven Track Record</h3>
              </div>
              <p>
                Agile Tech Labs boasts a proven track record of delivering
                top-notch testing services to clients across diverse industries.
                Our expertise and experience make us a dependable partner for
                all your testing needs.
              </p>
            </div>
            <p>
              In conclusion, selecting Agile Tech Labs for Managed Testing
              Services can revolutionize the quality, efficiency, and
              cost-effectiveness of your software testing processes. This, in
              turn, enables you to bring high-quality products to market at an
              accelerated pace. Join us on this exciting journey towards
              software testing excellence!
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Whychooseus;
