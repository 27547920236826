import React from "react";

function Mtsbanner() {
  return (
    <>
      <section className="mtsbanner"></section>
    </>
  );
}

export default Mtsbanner;
