import React, { useEffect, useState } from "react";
import Editmodal from "./Editmodal";
import hand from "../../../images/hand-globe.svg";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import Swal from "sweetalert2";
import AddCandidate from "./AddCandidate";
import { Link } from "react-router-dom";

function Talentpage() {
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [usersData, setUsersData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editedModal, setEditModal] = useState(false);

  const editModal = (id) => {
    setEditModal(true);
    setSelectedId(id);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditModal(false);
    setSelectedId("");
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  // Fatch candidate
  useEffect(() => {
    axios
      .get("https://agile.digiwbs.com/Admin/api/candidates")
      .then((result) => {
        // console.log("Data fetched:", result.data);
        setUsersData(result.data);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Failed to fetch user data",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }, []);


  // fatch candidate by id
  useEffect(() => {
    if (selectedId) {
      axios
        .get(`https://agile.digiwbs.com/Admin/api/candidates/${selectedId}`)
        .then((result) => {
          // setUser(result.data);
          // console.log("Data fetched by Id:", result.data);
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Failed to fetch user data",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  }, [selectedId]);


// Delete Candidate
  const deleteCandidate = (selectedId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `https://agile.digiwbs.com/Admin/api/candidates/${selectedId}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            Swal.fire("Deleted!", "User has been deleted.", "success");
            // Fetch updated data after deletion
            axios
              .get("https://agile.digiwbs.com/Admin/api/candidates")
              .then((result) => {
                setData(result.data);
              })
              .catch((err) => {
                console.error("Error fetching user data:", err);
                Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Failed to fetch updated user data",
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          })
          .catch((err) => {
            Swal.fire(
              "Error!",
              err.response?.data?.message || "Failed to delete user.",
              "error"
            );
          });
      }
    });
  };

  // MUI Table data Columns
  const columns = [
    {
      name: "experience",
      label: "Exprience",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => ` ${value}`,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <>
            <i
              className="fa-solid fa-envelope me-2"
              style={{ color: "#0074FF" }}
            ></i>
            {value}
          </>
        ),
      },
    },
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <>
            <i
              className="fa-solid fa-calendar-days me-2"
              style={{ color: "#0074FF" }}
            ></i>
            {moment(value).format("DD MMM, yyyy")}
          </>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <>
            <button className="me-3">
              <i className="fa-regular fa-eye" style={{ color: "#0074FF" }}></i>
            </button>
            <button className="me-3 " onClick={() => editModal(value)}>
              <i className="fa fa-pen" style={{ color: "#0074FF" }}></i>
            </button>
            <button onClick={() => deleteCandidate(value)}>
              <i className="fa fa-trash"></i>
            </button>
          </>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "vertical",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50],
    search: true,
    print: false,
    download: true,
    viewColumns: true,
    pagination: true,
    serverSide: false,
    selectableRows: "none",
  };

  return (
    <>
      <div className="home-page">
        <h2>Talent List</h2>
        <div className="home-row">
          <div className=" left-col">
            <div className="icon-text">
              <img src={hand} alt="icon-text" />
              <p>Services List</p>
            </div>
            <ul>
              {data &&
                data.map((val) => (
                  <li
                    key={val._id}
                    onClick={() =>
                      handleItemClick({ name: val.name, _id: val._id })
                    }
                    className={selectedItem.name === val.name ? "activee" : ""}
                  >
                    {val.name}
                  </li>
                ))}
            </ul>
          </div>
          <div className=" right-col">
            <div className="service-tab">
              <div className="service-head">
                <h2>Candidates</h2>
                <div>
                  <Link to="/admin/talent/uploadfile">
                    <button className="upload-file btn">Upload File</button>
                  </Link>
                  <button className="add-newbtn" onClick={openModal}>
                    + Add New
                  </button>
                </div>
              </div>
              <div className="service-add">
                <MUIDataTable
                  // title={"Candidates"}
                  data={usersData}
                  columns={columns}
                  options={options}
                />
              </div>
            </div>
            {/* {renderContent()} */}
          </div>
        </div>
      </div>
      <AddCandidate
        show={showModal}
        handleClose={closeModal}
        id={selectedItem}
      />
      <Editmodal
        show={editedModal}
        handleClose={closeModal}
        userId={selectedId}
      />
    </>
  );
}

export default Talentpage;
