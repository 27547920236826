import React from "react";
import NavbarWhite from "../Home/NavbarWhite";

function Current() {
  return (
    <>
      <section>
        <NavbarWhite />
      </section>
    </>
  );
}

export default Current;
