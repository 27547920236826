import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address"),
  // cv: Yup.mixed()
  //   .test("fileType", "Only PDF files are allowed", (value) => {
  //     if (value) {
  //       return value && value.type === "application/pdf";
  //     }
  //     return true; // Allow empty field (no file selected)
  //   })
  //   .test("fileSize", "File size too large", (value) => {
  //     if (value) {
  //       return value.size <= 2048; // 2MB
  //     }
  //     return true;
  //   }),
});

function Editmodal({ show, handleClose, userId }) {
  const modalStyles = {
    display: show ? "block" : "none",
  };

  const [data, setData] = useState({});

  useEffect(() => {
    if (userId) {
      axios
        .get(`https://agile.digiwbs.com/Admin/api/candidates/${userId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setData(result.data);
          formik.setValues(result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  const formik = useFormik({
    initialValues: {
      id: data.id,
      cv: "",
      date: data.date || "",
      email: data.email || "",
      experience: data.experience || "",
      primary_skills: data.primary_skills || "",
      secondary_skills: data.secondary_skills || "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      axios
        .put(
          `https://agile.digiwbs.com/Admin/api/candidates/${userId}`,
          formData
        )
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response?.data?.message || "Update failed",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  });

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    formik.setFieldValue("cv", file);
  };

  return (
    <div className="modal edit-modal" style={modalStyles}>
      <div className="modal-content">
        <div className="edit-head">
          <h2>Candidate Detail</h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="d-flex gap-3">
              <div className="d-grid edit-half">
                <label>Date</label>
                <input
                  type="date"
                  placeholder="Date"
                  alt="Date"
                  name="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date || ""}
                />
                {formik.touched.date && formik.errors.date ? (
                  <div className="error">{formik.errors.date}</div>
                ) : null}
              </div>
              <div className="d-grid edit-half">
                <label>Experience</label>
                <input
                  type="text"
                  placeholder="Experience"
                  alt="Experience"
                  name="experience"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.experience || ""}
                />
                {formik.touched.experience && formik.errors.experience ? (
                  <div className="error">{formik.errors.experience}</div>
                ) : null}
              </div>
            </div>
            <div className="d-grid">
              <label>Primary Skills</label>
              <input
                type="text"
                placeholder="Primary Skills"
                alt="Primary Skills"
                name="primary_skills"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.primary_skills || ""}
              />
              {formik.touched.primary_skills && formik.errors.primary_skills ? (
                <div className="error">{formik.errors.primary_skills}</div>
              ) : null}
            </div>
            <div className="d-grid">
              <label>Secondary Skills</label>
              <input
                type="text"
                placeholder="Secondary Skills"
                alt="Secondary Skills"
                name="secondary_skills"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondary_skills || ""}
              />
              {formik.touched.secondary_skills &&
              formik.errors.secondary_skills ? (
                <div className="error">{formik.errors.secondary_skills}</div>
              ) : null}
            </div>
            <div className="d-flex gap-3">
              <div className="d-grid edit-half">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  alt="Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email || ""}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="d-grid edit-half">
                <label>Upload CV (PDF only)</label>
                <input
                  type="file"
                  accept=".pdf"
                  placeholder="PDF"
                  alt="Upload"
                  id="upload-cv"
                  hidden
                  name="cv"
                  onChange={handleFileChange}
                />
                {formik.touched.cv && formik.errors.cv ? (
                  <div className="error">{formik.errors.cv}</div>
                ) : null}
                <label htmlFor="upload-cv" className="uploadcv">
                  <i className="fa-solid fa-file-arrow-up"></i>
                </label>
              </div>
            </div>
          </div>
          <div className="tablebtn">
            <Link className="cnclbtn" onClick={handleClose}>
              Discard
            </Link>
            <button type="submit" className="addbtn">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Editmodal;
