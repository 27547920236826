import React from 'react'
import Modal from "react-bootstrap/Modal";
function Botmigration({ show, handleClose }) {
   
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
          <div className=" rpafocusmodalhead">
            <h2>RPA Bot Migration</h2>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
          </div>
          <p className="rpafocusmodalpara">
            Conducting a comprehensive analysis of existing RPA bots and their
            dependencies is fundamental to identifying migration requirements
            accurately, including migrations between different RPA tools and
            from legacy systems to the cloud. Leveraging version control systems
            and automated deployment tools ensures a smooth migration process,
            maintaining consistency across diverse environments and facilitating
            transitions between different RPA platforms or from on-premises to
            cloud-based solutions. Implementing robust data migration strategies
            facilitates the seamless transfer of bot configurations and state
            information, ensuring compatibility between disparate systems and
            minimizing disruptions during the migration phase. Rigorous testing
            and performance monitoring of migrated bots validate their
            functionality across various environments, guaranteeing optimal
            performance post-migration and enabling organizations to leverage
            the full capabilities of their chosen RPA platforms.
          </p>
          <br />

          <ol>
            <li>Thorough analysis of existing RPA bots and dependencies</li>
            <li>
              Utilization of version control systems and automated deployment
              tools
            </li>
            <li>Implementation of robust data migration strategies</li>
            <li>
              Rigorous testing and performance monitoring of migrated bots
            </li>
          </ol>
       </Modal>
    </>
  );
}

export default Botmigration