import React from "react";
import Rpabanner from "../RPA/Rpabanner";
import WhatisRpa from "../RPA/WhatisRpa";
import Focuscontact from "../RPA/Focuscontact";
import NavbarWhite from "../Home/NavbarWhite";
import Benifitrpa from "../RPA/Benifitrpa";
import Benifitposter from "../RPA/Benifitposter";
import Postercontent from "../RPA/Postercontent";
import WhyATL from "../RPA/WhyATL";
import Automationposter from "../RPA/Automationposter";
import Rpafaq from "../RPA/Rpafaq";
import Rpatechnologi from "../RPA/Rpatechnologi";
import Rpafocus from "../RPA/Rpafocus";

const Slider = () => {
  return (
    <>
      <NavbarWhite />
      <Rpabanner />
      <WhatisRpa />
      <Benifitrpa />
      <Rpafocus/>
      <Rpatechnologi />
      <Benifitposter />
      <Postercontent />
      <WhyATL />
      <Automationposter />
      <Rpafaq />
      <Focuscontact />
    </>
  );
};

export default Slider;
