import React from 'react'
import Modal from "react-bootstrap/Modal";

function Performance({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Performance Optimization</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We fine-tune your applications to run faster and more efficiently,
          improving overall productivity and user satisfaction.
        </p>
      </Modal>
    </>
  );
}

export default Performance