import React from "react";
import { Link } from "react-router-dom";

function Whyitstaff() {
  return (
    <>
      <section className="whatmts">
        <div className="container mtscontainer">
          <h2>IT Staffing Solutions</h2>
          <p>
            At Agile Tech Labs, we understand that finding the right talent is
            crucial to the success of your projects. Our IT Staffing Solutions
            are designed to provide you with highly skilled professionals who
            align with your business needs and goals. Whether you require
            temporary, contract-to-hire, or permanent placements, we deliver
            top-tier talent that drives innovation and growth.
          </p>
          <br />
          <h2 className="mt-5">Partner with Agile Tech Labs</h2>
          <p>
            Let Agile Tech Labs be your trusted partner in IT staffing. Our
            commitment to quality, integrity, and customer satisfaction ensures
            that we deliver exceptional staffing solutions that meet and exceed
            your expectations. Contact us today to learn more about how we can
            help you build a strong, dynamic IT team.
          </p>
          <Link to="https://www.ceipal.com/">
          <button className="btn btn-primary me-5 mt-4">Upload resume</button>
          </Link>
          <Link to="https://www.roberthalf.com/us/en">
          <button className="btn btn-success  mt-4">Hire Talent</button>
          </Link>
        </div>
      </section>
    </>
  );
}

export default Whyitstaff;
