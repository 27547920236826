import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
// import upload from "../../../images/upload-img.svg";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
// import { getToken } from "../../../utils/helper";
import Swal from "sweetalert2";
const baseUrl = process.env.REACT_APP_BASE_URL;

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter Title"),
  content: Yup.string()
    .min(6, "Must be 6 characters or greater")
    .required("Please enter content"),
  learn: Yup.string().required("Please enter learn link"),
  contect: Yup.string().required("Please enter contect link"),
  image: Yup.mixed().required("Please upload an image"),
});

function Sectionmodal({ show, handleClose, fetchSectionData }) {
  // let token = getToken();
  const modalStyles = {
    display: show ? "block" : "none",
  };

  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState("");

  const formik = useFormik({
    initialValues: {
      name: "",
      content: "",
      learn: "",
      contect: "",
      image: null,
    },
    validationSchema,
    onSubmit: (values) => {
      values.pageId = id;
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("content", values.content);
      formData.append("learn", values.learn);
      formData.append("contect", values.contect);
      formData.append("image", values.image);

      axios
        .post(`${baseUrl}AddSectionToPage`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          formik.resetForm();
          handleClose();

          fetchSectionData();
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Section creatd Success",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  const handleImageChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    // Update the formik field with the selected image
    formik.setFieldValue("image", file);

    // Use FileReader to display a preview of the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleDiscard = () => {
    setImagePreview("");
    formik.resetForm();
    handleClose();
  };

  return (
    <>
      <div className="modal service-modal" style={modalStyles}>
        <div className="modal-content">
          <div className="service-head">
            <h2>Add New Section</h2>
            {/* <span className="close" onClick={handleClose}>
              &times;
            </span> */}
          </div>
          <hr />
          <form>
            <div>
              <label for="input-file" className="upload">
                <input
                  type="file"
                  accept="image/*"
                  id="input-file"
                  hidden
                  onChange={handleImageChange}
                />
                <div className="upload-img">
                  <img
                    src={imagePreview}
                    alt="Drag and Drop or Click to upload Image"
                  />
                  {/* imagePreview */}
                  {/* <p>Drag and Drop or Click to upload Image</p> */}
                </div>
              </label>
            </div>
            <div>
              <input
                type="text"
                placeholder="Title"
                alt="Title"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                placeholder="Description"
                alt="description"
                name="content"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.content}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="error">{formik.errors.content}</div>
              ) : null}
            </div>
            <div className="d-flex gap-3">
              <input
                type="text"
                placeholder="Learn"
                alt="Learn"
                name="learn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.learn}
              />
              {formik.touched.learn && formik.errors.learn ? (
                <div className="error">{formik.errors.learn}</div>
              ) : null}
              <input
                type="text"
                placeholder="Contact"
                alt="Contact"
                name="contect"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contect}
              />
              {formik.touched.contect && formik.errors.contect ? (
                <div className="error">{formik.errors.contect}</div>
              ) : null}
            </div>
            <div className="tablebtn mt-4">
              <Link className="cnclbtn" onClick={handleDiscard}>
                Discard
              </Link>
              <Link className="addbtn" onClick={formik.handleSubmit}>
                Save
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Sectionmodal;
