import React from "react";
import { Accordion } from "react-bootstrap";

function Mtsfaq() {
  return (
    <>
      <section className="rpafaq pt-5 pb-5">
        <div className="container">
          <div className="row mt-5">
            <h2 className="mb-5">
              Frequently Asked{" "}
              <span style={{ color: "#003eae" }}>Questions</span> about Managed
              Testing Services (MTS)
            </h2>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What exactly are Managed Testing Services (MTS)?
                </Accordion.Header>
                <Accordion.Body>
                  MTS involves outsourcing software testing tasks to a
                  specialized service provider like Agile Tech Labs, who takes
                  care of everything from planning to execution to reporting on
                  behalf of the client.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What kind of services can you expect from Managed Testing
                  Services?
                </Accordion.Header>
                <Accordion.Body>
                  Think functional testing, non-functional testing, automation
                  testing, mobile testing, security testing, and more - all
                  under one roof!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What are the benefits of Managed Testing Services.
                </Accordion.Header>
                <Accordion.Body>
                  Picture this: access to specialized expertise, cost
                  efficiency, the freedom to focus on your core business
                  activities, scalability, access to cutting-edge tools and
                  technologies, improved quality, faster time-to-market, and
                  risk mitigation. Sounds pretty amazing, right?
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  But how does Managed Testing Services differ from traditional
                  in-house testing?
                </Accordion.Header>
                <Accordion.Body>
                  Well, with MTS, you're outsourcing testing activities to a
                  specialized service provider (Agile Tech Labs), whereas
                  traditional in-house testing relies on an internal testing
                  team.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  How do you choose the right Managed Testing Services provider
                  for your needs?
                </Accordion.Header>
                <Accordion.Body>
                  Look for expertise, experience, a wide range of services,
                  cost-effectiveness, scalability, a customer-centric approach,
                  and a solid track record.
                  <br />
                  <br />
                  And guess what? Managed Testing Services aren't just for one
                  industry - they can benefit healthcare, finance, retail,
                  manufacturing, technology, and more!
                  <br />
                  <br />
                  At <strong>Agile Tech Labs</strong>, we offer all these
                  qualities, making us an ideal choice.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How can Managed Testing Services can help improve software
                  quality.
                </Accordion.Header>
                <Accordion.Body>
                  By providing thorough and effective testing, identifying and
                  addressing issues early on, and ensuring compliance with
                  standards and regulations, MTS can take your software quality
                  to the next level. Exciting stuff, right?
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Can Managed Testing Services assist with compliance testing?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, MTS providers at Agile Tech Labs frequently offer
                  compliance testing services to assist organizations in
                  ensuring that their software complies with relevant industry
                  standards, regulations, and legal requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What is the typical engagement model for Managed Testing
                  Services?
                </Accordion.Header>
                <Accordion.Body>
                  The typical engagement model for Managed Testing Services can
                  vary, but it often includes options such as project-based
                  testing, dedicated testing teams, or ongoing testing support
                  tailored to the client's specific needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  How can I begin with Managed Testing Services?
                </Accordion.Header>
                <Accordion.Body>
                  To initiate Managed Testing Services, you can contact Agile
                  Tech Labs, discuss your requirements, and collaborate with us
                  to develop a customized testing solution that aligns with your
                  needs.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Mtsfaq;
