import React from "react";
import stage from '../../images/managed-testing-service-stages.png'
function Offringaproach() {
  return (
    <>
      <section className=" offringaproach pt-5 pb-5">
        <div className="container">
          <h2>Agile Tech Labs Managed Service Testing Offering Approach</h2>
          <div className="mt-4">
            <h4>Step 1: Discovery</h4>
            <p>
              A ScienceSoft QA consultant looks into your current development
              and testing methods, checks your IT setup, reviews project
              documents, and talks to key people to understand what testing
              needs to be done and any problems or risks. Based on this, the
              consultant makes a detailed plan for how to work together.
            </p>
          </div>
          <div>
            <h4>Step 2: Transition</h4>
            <p>
              The QA consultant shares what they found in Stage 1 with
              ScienceSoft’s testing team. They make a strategy for testing, find
              ways to improve how things are tested, and plan how to make those
              improvements happen. The team sets up tools needed for testing and
              takes charge of managing the testing process and making sure the
              software is good quality.
            </p>
          </div>
          <div>
            <h4>Step 3: Service delivery</h4>
            <p>
              The testing team gets going on the main tasks of the project:
              planning tests, designing them, doing the tests, and reporting
              what they find.
            </p>
            <p>
              <strong> Test Planning</strong> We make a plan for testing that
              fits the risks and limits of your project. We assign experts with
              the right skills and pick the best way to set up the test
              environment.
            </p>
            <p>
              <strong>Test Design</strong> We make test cases that can be used
              again, split up in a way that makes sense, and follow a standard
              naming and structure. We also prepare test data that keeps your
              information safe and follows the rules.
            </p>
            <p>
              If we’re doing automated tests, we make systems that can be
              changed without much work and keep working well even as the
              project changes.
            </p>
          </div>
          <div>
            <p>
              <strong>Test Execution </strong>We test your software in lots of
              ways, including:
            </p>
            <li>Checking if it does what it’s meant to (Functional)</li>
            <li>Testing different setups (Configuration)</li>
            <li>Making sure it’s safe (Security) </li>{" "}
            <li>Checking how well it works with other things (Integration)</li>{" "}
            <li>Seeing how easy it is to use (Usability) </li>
            <li>Making sure everyone can use it (Accessibility)</li>
            <li>Testing online features (Digital) </li>
            <li>Checking how it works in different places (Localization)</li>
            <br />
            <p>
              Some tests are done by hand, and some are done automatically, like
              checking how the user interface works or testing APIs.
            </p>
            <p>
              <strong>Test Reporting</strong> We give you detailed reports on
              how the tests went, either when we finish a part of the work or at
              a schedule that works for you, like every day or every week.
            </p>
            <p>
              <strong>Stage 4: Service evolution </strong>Once everything’s
              going well, we keep improving how we test things:
            </p>{" "}
            <li>
              Adding more kinds of tests like checking how well it works under
              pressure (Performance) or if it’s safe from problems (Security).
            </li>
            <li>
              Adding new ways to help, like setting up a Testing Center of
              Excellence.
            </li>
            <li>Doing more tests automatically to save time.</li>
          </div>
          <div className="mt-5">
            <img src={stage} alt="manage testing"/>
          </div>
        </div>
      </section>
    </>
  );
}

export default Offringaproach;
