import React from "react";
import expertise from "../../images/image 117.png";
import record from "../../images/image 113.png";
import solution from "../../images/image 114.png";
import scalable from "../../images/image 115.png";
import cost from "../../images/image 116.png";

function Aboutchoose() {
  return (
    <>
      <section className="mtschoose pt-5  pb-5">
        <div className="container">
          <h2>WHY CHOOSE AGILE TECH LABS?</h2>

          <div className="row w-100 mts-row">
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={expertise} alt="Expertise" />
                <h3>Proven Expertise</h3>
              </div>
              <p>
                With over two decades of experience, Agile Tech Labs has a track
                record of delivering innovative and reliable technology
                solutions across various industries. Our team of seasoned
                professionals brings deep technical knowledge and practical
                insights to every project.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={record} alt="Comprehensive Services" />
                <h3>Client-Centric Approach</h3>
              </div>
              <p>
                We put our clients at the center of everything we do. We take
                the time to understand your unique needs and challenges,
                ensuring that our solutions are tailored to meet your specific
                goals. Your success is our priority.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={solution} alt="Cost-Effectiveness" />
                <h3>Cutting-Edge Technology</h3>
              </div>
              <p>
                We stay ahead of the curve by embracing the latest advancements
                in technology. Our solutions are built with scalability,
                security, and future-readiness in mind, ensuring your
                organization is well-equipped for the challenges of tomorrow.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={scalable} alt="Focus on Quality" />
                <h3>Comprehensive Solutions</h3>
              </div>
              <p>
                From IT staffing and consulting to product re-engineering and
                legacy application modernization, we offer a wide range of
                services to meet your needs. Our holistic approach ensures that
                all aspects of your technology ecosystem are optimized for
                performance and efficiency.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3>Quality and Timeliness</h3>
              </div>
              <p>
                We are committed to delivering high-quality solutions on time
                and within budget. Our proven delivery processes, combined with
                a repository of reusable components, enable us to consistently
                meet our clients' expectations.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3> Innovation and Adaptability</h3>
              </div>
              <p>
                In a rapidly evolving digital landscape, we pride ourselves on
                our ability to innovate and adapt. We collaborate closely with
                our clients to develop solutions that not only solve today’s
                problems but also anticipate future needs.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3> Long-Term Partnership</h3>
              </div>
              <p>
                At Agile Tech Labs, we believe in building lasting relationships
                with our clients. We are more than just a service provider; we
                are your trusted partner in navigating the complexities of
                technology and driving sustainable growth.
              </p>
            </div>            
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutchoose;
