import React from "react";
import Modal from "react-bootstrap/Modal";
function Technologyupgrade({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Technology Upgrades</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We migrate your old applications to newer, more efficient technologies
          that improve performance, security, and user experience.
        </p>
      </Modal>
    </>
  );
}

export default Technologyupgrade;
