import React from "react";
import Modal from "react-bootstrap/Modal";
function Datamodern({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Data Modernization</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We ensure your data is secure, accessible, and organized by upgrading
          databases and data management systems.
        </p>
      </Modal>
    </>
  );
}

export default Datamodern;
