import React from "react";
import expertise from '../../images/expertice2.png'
import record from "../../images/record2.png";
import solution from "../../images/solution2.png";
import scalable from "../../images/scaliblity4.png";
import cost from "../../images/costeffect1.png";
import compransive from "../../images/compransive1.png";
import client from "../../images/client2.png";
import inovatio from "../../images/inovation1.png";


function WhyATL() {
  return (
    <section className="whychooserpa pt-5  mt-5 mb-5">
      <div className="container">
        <h2>Why Choose Us for Robotics Process Automation (RPA):</h2>
        <p>
          At Agile Tech Labs, we are the experts in delivering top-tier Robotics
          Process Automation (RPA) services that will take your business to new
          heights of efficiency and productivity. Our innovative approach and
          industry-leading expertise ensure that we provide comprehensive RPA
          solutions that streamline your operations, reduce costs, and enhance
          overall performance. But that's not all! Let's dive into the exciting
          reasons why you should choose our services:
        </p>
        <div className="row w-100 whychoose-row">
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={expertise} alt="expertise" />
              <h3>Expertise</h3>
            </div>
            <p>
              Our team of seasoned professionals boasts extensive experience in
              RPA implementation and a deep understanding of industry best
              practices. Rest assured, your automation projects will be
              flawlessly executed by our experts.
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={record} alt="record" />
              <h3>Proven Track Record</h3>
            </div>
            <p>
              We have a solid history of successful RPA implementations across
              various industries. Our ability to deliver tangible results and
              drive business growth has been demonstrated time and time again.
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={solution} alt="solution" />
              <h3>Customized Solutions</h3>
            </div>
            <p>
              We understand that every business is unique, and that's why we
              offer customized RPA solutions tailored to your specific needs and
              objectives. Our goal is to guarantee maximum value delivery for
              your business.
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={scalable} alt="scalable" />
              <h3>Scalability</h3>
            </div>
            <p>
              Our RPA solutions are designed to grow with your business. Whether
              you're starting small or looking to expand your automation
              efforts, our scalable solutions can adapt to your evolving needs.
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={cost} alt="cost" />
              <h3>Cost-Effectiveness</h3>
            </div>
            <p>
              By leveraging our RPA solutions, you can significantly reduce
              operational costs and improve efficiency, leading to substantial
              cost savings over time. Imagine the impact on your bottom line!
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={compransive} alt="compransive" />
              <h3>Comprehensive Services</h3>
            </div>
            <p>
              From consulting and development to implementation and support, we
              provide end-to-end RPA services. Our goal is to ensure a seamless
              automation journey for your business, taking care of every step
              along the way.
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={client} alt="client" />
              <h3>Client-Centric Approach</h3>
            </div>
            <p>
              We prioritize client satisfaction and work closely with you to
              understand your unique challenges and goals. By doing so, we
              deliver solutions that address your specific needs and exceed your
              expectations.
            </p>
          </div>
          <div className="whychoose-content">
            <div className="d-flex align-items-center">
              <img src={inovatio} alt="inovation" />
              <h3>Innovation</h3>
            </div>
            <p>
              Our commitment to innovation means that you'll always have access
              to the latest RPA technology. Stay ahead of the competition and
              drive continuous improvement.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyATL;
