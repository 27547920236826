import React, { useEffect, useState } from "react";
import UserContext from "./UserContext";

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState("");

  useEffect(() => {
    const storedUser = localStorage.getItem("Login");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const updateUser = (newUserInfo) => {
    setUser(newUserInfo);
    localStorage.setItem("user", JSON.stringify(newUserInfo)); // Update in localStorage
  };

  return (
    <UserContext.Provider value={{ user, setUser, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
