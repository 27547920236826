import React from 'react'
import Inotech from './Inotech'
import { Link} from 'react-router-dom'

function Qaauto() {
    return (
      <>
        <section className="qa ino">
          <div className="container qahead ino-container">
            <div className="heading">
              <h1>QA Automation</h1>
              <Inotech />
            </div>

            <div className="btngroup d-flex">
              <button className="learn">Learn More</button>
              <Link to="/contact">
                <button className="contact">Contact</button>
              </Link>
            </div>
          </div>
        </section>
      </>
    );
}

export default Qaauto