import React from "react";
import Cyberbanner from "../Cyber security/Cyberbanner";
import Whatiscyber from "../Cyber security/Whatiscyber";
import Cyberfocus from "../Cyber security/Cyberfocus";
import Cyberchooseus from "../Cyber security/Cyberchooseus";
import Focuscontact from "../RPA/Focuscontact";
import Cyberfaq from "../Cyber security/Cyberfaq";

function CyberRoute() {
  return (
    <>
      <Cyberbanner />
      <Whatiscyber />
      <Cyberfocus />
      <Cyberchooseus />
      <Cyberfaq/>
      <Focuscontact />
    </>
  );
}

export default CyberRoute;
