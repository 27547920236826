import axios from "axios";
import React, { useEffect, useState } from "react";

function Uploadfile() {
  const [filedata, setFiledata] = useState([]);

  useEffect(() => {
    axios
      .get("https://agile.digiwbs.com/Admin/api/talent-files/")
      .then((result) => {
        // console.log(result.data);
        setFiledata(result.data);
      });
  }, []);
  

  return (
    <>
      <div className="uploadfile">
        <h2>Upload Your Data</h2>
        <div className="mainupload" key={filedata.id}>
          <div className="left-upload">
            <h4>File</h4>
            <label for="uploaded">
              <i class="fa-solid fa-file-import"></i>
            </label>
            <input type="file" id="uploaded" hidden />
            <p>{filedata.file}</p>
          </div>

          <div className="right-upload">
            <h4>CV(ZIP*)</h4>
            <label for="uploaded">
              <i class="fa-solid fa-file-zipper"></i>{" "}
            </label>
            <input type="file" id="uploaded" hidden />
            <p>{filedata.cv}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Uploadfile;
