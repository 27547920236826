import React from "react";
import Modal from "react-bootstrap/Modal";
function Botdevelopment({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className=" rpafocusmodalhead">
          <h2>RPA Bot Development</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          In developing automation solutions, we leverage a range of RPA tools
          including UiPath, Automation Anywhere, Power Automate, Blue Prism,
          Pega, and more. Our approach focuses on crafting tailored automation
          bots or agents designed to address the specific nuances of your
          business processes. Utilizing industry best practices in bot
          development, we ensure that our solutions are robust, efficient, and
          aligned with your organization's goals. We advocate for a phased
          deployment strategy, beginning with pilot projects to validate the
          effectiveness and scalability of the automation solution. This
          iterative approach allows us to fine-tune the bots and ensure seamless
          integration into your operations before full implementation, resulting
          in optimized outcomes and maximum return on investment
        </p>
        <br />

        <ol>
          <li>
            Utilization of diverse RPA tools including UiPath, Automation
            Anywhere, Power Automate, Blue Prism, Pega, etc.
          </li>
          <li>
            Tailored automation bots crafted to address specific business
            processes
          </li>
          <li>Implementation of industry best practices in bot development</li>
          <li>
            Phased deployment strategy with pilot projects for validation before
            full implementation
          </li>
        </ol>
      </Modal>
    </>
  );
}

export default Botdevelopment;
