import React from "react";
import Modal from "react-bootstrap/Modal";
import cyber from '../../../images/cybersecurity.png'

function Managed({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal cybermodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Managed Cybersecurity Services</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <h2>one-stop, Complete, and Trusted Cyber Defense</h2>
        <p className="rpafocusmodalpara">
          Agile Tech Labs' Managed Cybersecurity Services offer expert
          monitoring and oversight of your network. Our team of cybersecurity
          professionals constantly watches your network for any suspicious
          activity, ensuring that potential threats are detected and addressed
          quickly. This proactive approach helps to significantly reduce the
          risk of cybersecurity breaches, keeping your data and systems safe.
        </p>
        <p>
          In addition to reducing risk, our services also improve your
          time-to-remediation. By promptly identifying and addressing security
          issues, we minimize the impact of any potential attacks. This means
          your business can recover faster and more efficiently, reducing
          downtime and maintaining your operations with minimal disruption. With
          Agile Tech Labs, you get peace of mind knowing that your network is in
          expert hands.
        </p>
        <p>
          Our complete set of managed security solutions and expert advisors
          handle your real-time cybersecurity risks all day, every day, as an
          extension of your security team.
        </p>
        <img src={cyber}  alt="cyber security" className="cyber-img"/>
      </Modal>
    </>
  );
}

export default Managed;
