import React from "react";
import Modal from "react-bootstrap/Modal";
function Devimplement({ show, handleClose }) {

  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Development and implementation</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We switch from traditional methods to Agile and DevOps, allowing for
          quicker and repeated releases, while ensuring business and
          <br /> IT work together seamlessly.
        </p>
      </Modal>
    </>
  );
}

export default Devimplement;
