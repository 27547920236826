import React from "react";

function TransformAproach() {
  return (
    <>
      <section className="mtsbenifit pb-5">
        <div className="container">
          <h2>Agile Tech Labs Approach:</h2>
          <h4 className="mt-3">Assessment</h4>
          <li>
            Quickly evaluate the current state and needs of the agile
            environment to set priorities and identify first steps.
          </li>
          <li>
            Collaborate with both leaders and team members to plan the way
            forward, using insights from all levels of the organization.
          </li>
          <li>
            Rapidly document the current state and create a recommended
            improvement plan, including Scrum, Scaled Scrum, and DevOps,
            focusing on speed and business value.
          </li>

          <h4 className="mt-5">
            Embedded Expertise, Evaluation, and Recommendations
          </h4>
          <li>
            Enhance Scrum and Scaled Scrum teams, and DevOps roles to help them
            deliver better and continuously improve.
          </li>
          <li>
            Offer effective ways to identify and remove impediments, blockers,
            and obstacles.
          </li>
          <li>
            Continuously evaluate the maturity of Scrum teams and suggest
            organizational support or changes to speed up their development,
            drawing on our experience with other clients.
          </li>

          <h4 className="mt-5">Training and Workshops</h4>
          <li>
            Provide effective training courses to prepare individuals for
            scrum.org's Professional Scrum Master or Professional Product Owner
            Certification, or to understand the principles of Scaled Scrum.
          </li>
          <li>
            Conduct continuous workshops for teams and leaders to assess current
            strengths and weaknesses in their approach, and identify areas for
            improvement.
          </li>
          <br />
          <p>
            Embark on your agile transformation journey with Agile Tech Labs and
            unlock your organization's full potential!
          </p>
        </div>
      </section>
    </>
  );
}

export default TransformAproach;
