import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";

const validationSchema = Yup.object({
  date: Yup.date().required("Date is required"),
  experience: Yup.string().required("Experience is required"),
  primary_skills: Yup.string().required("Primary Skills is required"),
  secondary_skills: Yup.string().required("Secondary Skills is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  cv: Yup.mixed()
    .required("CV is required")
    .test("fileType", "Only PDF files are allowed", (value) => {
      if (value) {
        return value && value.type === "application/pdf";
      }
      return true; // Allow empty field (no file selected)
    })
    .test("fileSize", "File size too large", (value) => {
      if (value) {
        return value.size <= 2048; // 5MB
      }
      return true;
    }),
});

const AddCandidate = ({ show, handleClose }) => {
  const modalStyles = {
    display: show ? "block" : "none",
  };

  const formik = useFormik({
    initialValues: {
      cv: "",
      date: "",
      email: "",
      primary_skills: "",
      secondary_skills: "",
      experience: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      axios
        .post("https://agile.digiwbs.com/Admin/api/candidates", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Candidate created successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          formik.resetForm();
          handleClose();
        })
        .catch((err) => {
          const message =
            err.response?.data.message ||
            "An unexpected error occurred. Please try again later.";
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  });

  const handleFileChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    formik.setFieldValue("cv", file);
  };

  return (
    <div className="modal edit-modal" style={modalStyles}>
      <div className="modal-content" aria-labelledby="modal-title">
        <div className="edit-head">
          <h2 id="modal-title">Add New Candidate</h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="d-flex gap-3">
              <div className="d-grid edit-half">
                <label>Date</label>
                <input
                  type="date"
                  placeholder="Date"
                  name="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date}
                />
                {formik.touched.date && formik.errors.date && (
                  <div className="error">{formik.errors.date}</div>
                )}
              </div>
              <div className="d-grid edit-half">
                <label>Experience</label>
                <input
                  type="text"
                  placeholder="Experience"
                  name="experience"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.experience}
                />
                {formik.touched.experience && formik.errors.experience && (
                  <div className="error">{formik.errors.experience}</div>
                )}
              </div>
            </div>
            <div className="d-grid">
              <label>Primary Skills</label>
              <input
                type="text"
                placeholder="Primary Skills"
                name="primary_skills"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.primary_skills}
              />
              {formik.touched.primary_skills &&
                formik.errors.primary_skills && (
                  <div className="error">{formik.errors.primary_skills}</div>
                )}
            </div>
            <div className="d-grid">
              <label>Secondary Skills</label>
              <input
                type="text"
                placeholder="Secondary Skills"
                name="secondary_skills"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.secondary_skills}
              />
              {formik.touched.secondary_skills &&
                formik.errors.secondary_skills && (
                  <div className="error">{formik.errors.secondary_skills}</div>
                )}
            </div>
            <div className="d-flex gap-3">
              <div className="d-grid edit-half">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error">{formik.errors.email}</div>
                )}
              </div>
              <div className="d-grid">
                <label>Upload CV (PDF only, max 1MB)</label>
                <input
                  type="file"
                  id="upload-cv"
                  hidden
                  name="cv"
                  onChange={handleFileChange}
                />
                {formik.touched.cv && formik.errors.cv && (
                  <div className="error">{formik.errors.cv}</div>
                )}
                <label htmlFor="upload-cv" className="uploadcv">
                  <i className="fa-solid fa-file-arrow-up"></i>
                </label>
              </div>
            </div>
          </div>
          <div className="tablebtn">
            <button type="button" className="cnclbtn" onClick={handleClose}>
              Discard
            </button>
            <button type="submit" className="addbtn">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCandidate;
