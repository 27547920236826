import React, { Suspense } from "react";
import Navbar from "./Home/Navbar";
import { Outlet, useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();

  // Check if the current route starts with '/admin'
  const isAdminRoute = location.pathname.startsWith("/admin");
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        {!isAdminRoute && <Navbar />}
        <Outlet />
      </Suspense>
    </>
  );
}

export default Layout;
