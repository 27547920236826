import React from 'react'
import Modal from "react-bootstrap/Modal";

function Systemintegration({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>System Integration</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We connect your modernized applications with other systems and
          platforms to create a seamless, integrated environment.
        </p>
      </Modal>
    </>
  );
}

export default Systemintegration