import React from "react";
import Modal from "react-bootstrap/Modal";

function Supportservice({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>RPA Support and Maintenance Services</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          AgileTechlabs has expertise in defining the optimal way to monitor and
          maintain your newly automated processes, reinforcing them with
          up-to-date features and increasing business efficiency.
        </p>
        <br />
        <p>
          Offer ongoing support and maintenance services to optimize RPA
          solutions, monitor performance metrics, and implement iterative
          enhancements, ensuring that customer service offerings remain
          effective and aligned with evolving business requirements and customer
          expectations.
        </p>
        <ol>
          <li>L1, L2, L3 Support</li>
          <li>Identification, Triage, Trouble Shooting, Resolution</li>
          <li>Bug fixes and minor enhancements to process automations</li>
          <li>
            Platform upgrade and update installation, configuration, and testing
          </li>
        </ol>
      </Modal>
    </>
  );
}

export default Supportservice;
