import React from "react";
import Modal from "react-bootstrap/Modal";
function Staffing({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal cybermodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Cyber Security Staffing</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We are driven by three core principles in changing Staff Augmentation
          for the better: cost, quality, and responsiveness. Whatever your need
          – be it a single resource or a whole team we will find you the right
          talent in a timely and cost-effective manner. A few roles include:
        </p>
        <li>Cyber Security Administration</li>
        <li>Cyber Security Architects</li>
        <li>Cyber Security Engineer</li>
        <li>Cyber Security Analyst</li>
        <li>Application Security Engineer</li>
        <li>Information Security Manager</li>
        <li>Network Security Engineer</li>
        <li>Cyber Security Auditor</li>
        <p>And many more…</p>
        <h2>Staff Augmentations options:</h2>
        <li>Contract Staffing</li>
        <li>Direct Perm Staffing</li>
        <li>Contract-to-Hire Staffing</li>
      </Modal>
    </>
  );
}

export default Staffing;
