import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/Logo-min.png";

function Forget() {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
  });
  return (
    <>
      <section className="forgot">
        <div className="container">
          <div className="forgotform">
            <div className="forgotlogo">
              <img src={logo} alt="Logo" />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <h2>Forgot Password</h2>
              </div>
              <div className="text-start">
                <label>Enter your Email</label>
                <br />
                <input
                  type="email"
                  placeholder="Email address"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                ></input>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <div></div>
              <div>
                <button type="submit">Send</button>
              </div>
              <div className="mt-3">
                <Link to="/login" className="forgetpass">
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Forget;
