import React from "react";

function Whatiscyber() {
  return (
    <>
      <section className="whatmts">
        <div className="container mtscontainer">
          <h2>Cybersecurity</h2>
          <p>
            Every day, online threats become more advanced, so your security
            must keep up. If you ignore or spend too little on cybersecurity,
            you might face a major data breach that could ruin your business.
            But staying safe is possible.
          </p>
          <br />
          <p>
            Agile Tech Labs offers affordable top-quality network security for
            small and medium businesses. Using the best tools, proven practices,
            and our cybersecurity expertise, we protect your business. Our team
            constantly monitors your network to defend against online attacks
            like data theft, ransomware, and phishing.
          </p>
          <br />

          <h2 className="mt-5">
            Agile Tech Labs Managed Cybersecurity Service (MCS){" "}
          </h2>
          <p>
            Agile Tech Labs' Managed CyberSecurity Service (MCS) include our
            complete set of proven cybersecurity solutions. These solutions help
            organizations detect, protect against, and recover from attacks.
          </p>
        </div>
      </section>
    </>
  );
}

export default Whatiscyber;
