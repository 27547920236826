import React from "react";
import expertise from "../../images/image 117.png";
import record from "../../images/image 113.png";
import solution from "../../images/image 114.png";
import scalable from "../../images/image 115.png";
import cost from "../../images/image 116.png";

function Cyberchooseus() {
  return (
    <>
      <section className="mtschoose pt-5  pb-5">
        <div className="container">
          <h2>Why Choose Agile Tech Labs for Your Cybersecurity</h2>
          <p>
            Agile Tech Labs will prevent and stop threats, so they don’t
            interrupt your business. We understand the critical importance of
            cybersecurity in today's digital landscape. With cyber threats
            constantly evolving and becoming more sophisticated, organizations
            need a reliable partner they can trust to protect their systems,
            networks, and data from potential attacks. Here's why Agile Tech
            Labs is the ideal choice for all your cybersecurity needs:
          </p>
          <div className="row w-100 mts-row">
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={expertise} alt="Expertise" />
                <h3>Comprehensive Solutions</h3>
              </div>
              <p>
                We offer a comprehensive range of cybersecurity solutions
                tailored to your specific requirements. Whether you're a small
                business or a large enterprise, our team of experts will work
                closely with you to design and implement a cybersecurity
                strategy that addresses your unique challenges and mitigates
                potential risks.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={record} alt="Comprehensive Services" />
                <h3>Expertise</h3>
              </div>
              <p>
                Our team of cybersecurity professionals brings extensive
                expertise and experience to the table. With years of hands-on
                experience in the field, they have a deep understanding of the
                latest cyber threats and trends, allowing them to develop
                proactive strategies to protect your organization from potential
                attacks.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={solution} alt="Cost-Effectiveness" />
                <h3>Proactive Approach</h3>
              </div>
              <p>
                At Agile Tech Labs, we believe in taking a proactive approach to
                cybersecurity. Rather than simply reacting to threats as they
                arise, we leverage the latest tools, technologies, and best
                practices to anticipate and prevent cyber attacks before they
                can cause harm to your systems and data.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={scalable} alt="Focus on Quality" />
                <h3>Customer Support</h3>
              </div>
              <p>
                We understand that cybersecurity is a 24/7 concern, which is why
                we provide round-the-clock customer support to our clients.
                Whether you have a question, need assistance with a security
                issue, or require emergency support, our dedicated team is
                always here to help, ensuring that your systems are protected at
                all times
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3>Cost-effective</h3>
              </div>
              <p>
                We believe that effective cybersecurity shouldn't break the
                bank. That's why we offer cost-effective solutions that provide
                excellent value for money. Our goal is to help you maximize your
                cybersecurity investment while minimizing your risk exposure,
                allowing you to focus on your core business activities with
                confidence.
              </p>
            </div>
            <p>
              When you choose Agile Tech Labs as your cybersecurity partner, you
              can rest assured knowing that you're in safe hands. With our
              comprehensive solutions, expert team, proactive approach,
              exceptional customer support, and cost-effective pricing, we're
              committed to helping you protect your organization from cyber
              threats and secure your digital future.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cyberchooseus;
