import React from "react";
import { NavLink, Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import logo from "../../images/mob-logo 2.png";
import scrum from "../../images/scrum master drop.png";
import rpa from "../../images/robotic-hand-holograms-rpa-concept 1.png";
import qa from "../../images/11667683_20945532 1-min.png";
import staf from "../../images/human-resources-concept-with-man 1-min.png";
import digi from "../../images/rpa-concept-with-blurry-hand-touching-screen 1.png";

function Homewhite() {
  const expand = () => {
    const searchbar = document.querySelector(".searchbar");

    if (searchbar) {
      if (searchbar.style.width === "0px" || searchbar.style.width === "") {
        searchbar.style.width = "150px";
      } else {
        searchbar.style.width = "0px";
      }
    }
  };
  return (
    <>
      <section>
        <nav className="navbar navbar-expand-lg nav2">
          <div className="home-full">
            <div className="sitelogo-mobile">
              <NavLink className="logo" to="/">
                <img src={logo} loading="lazy" alt="logo" />
              </NavLink>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">Menu</span>
            </button>

            <div
              className="collapse navbar-collapse menu2"
              id="navbarTogglerDemo02"
            >
              <div className="sitelogo">
                <NavLink className="logo" to="/">
                  <img src={logo} loading="lazy" alt="logo" />
                </NavLink>
              </div>
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item active">
                  <NavLink className="nav-link" to="/" activeClassName="active">
                    <i className="fa fa-house"></i>
                  </NavLink>
                </li>
                <li className="nav-item dropi">
                  <a
                    className="nav-link  dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Services
                  </a>
                  <div
                    className="dropdown-menu container"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="drop">
                      <div className="dropitem">
                        <img src={rpa} loading="lazy" alt="rpa" />
                        <Link to="/rpa">
                          <p>Robotic Process Automation</p>
                        </Link>
                        <Link to="/rpa">
                          <button>Learn</button>
                        </Link>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                      <div className="dropitem">
                        <img src={qa} loading="lazy" alt="qa" />
                        <Link to="/managed_testing_services">
                          <p>Managed Testing Services (MTS)</p>
                        </Link>
                        <Link to="/managed_testing_services">
                          <button>Learn</button>
                        </Link>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                      <div className="dropitem">
                        <img src={scrum} loading="lazy" alt="scrum" />
                        <p>Agile Transformation</p>
                        <Link to="/Agile_Transformation">
                          <button>Learn</button>
                        </Link>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                      <div className="dropitem">
                        <img src={staf} loading="lazy" alt="staff" />
                        <p>Staff Augmentation</p>
                        <Link to="/It-staffing">
                          <button>Learn</button>
                        </Link>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                      <div className="dropitem">
                        <img src={digi} loading="lazy" alt="digi" />
                        <p>Product Re-Engineering</p>
                        <Link to="/Product_re_engineering">
                          <button>Learn</button>
                        </Link>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                      <div className="dropitem">
                        <img src={staf} loading="lazy" alt="staff" />
                        <p>Generative AI (GEN AI)</p>
                        <button>Learn</button>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                      <div className="dropitem">
                        <img src={qa} loading="lazy" alt="qa" />
                        <p>Cybersecurity</p>
                        <Link to="/cyber-security">
                          <button>Learn</button>
                        </Link>
                        <Link to="/contact">
                          <button className="con">Contact</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/aboutslider"
                    activeClassName="active"
                  >
                    About Company
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Current Openings
                  </a>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/talent"
                    activeClassName="active"
                  >
                    Talent List
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    activeClassName="active"
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
              <div className="search-expand">
                <input
                  type="text"
                  placeholder="search..."
                  className="searchbar"
                />
                <SearchIcon onClick={expand} style={{ color: "#003eae" }} />
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}

export default Homewhite;
