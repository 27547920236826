import React from "react";
import { Accordion } from "react-bootstrap";

function Cyberfaq() {
  return (
    <>
      <section className="rpafaq pt-5 pb-5">
        <div className="container">
          <div className="row mt-5">
            <h2 className="mb-5">
              Frequently Asked{" "}
              <span style={{ color: "#003eae" }}>Questions</span> about
              Cybersecurity
            </h2>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>What is cybersecurity?</Accordion.Header>
                <Accordion.Body>
                  Cybersecurity refers to the practice of protecting systems,
                  networks, and data from digital attacks
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  What are Managed Cybersecurity Services?
                </Accordion.Header>
                <Accordion.Body>
                  Agile Tech Labs' Managed CyberSecurity Service (MCS) includes
                  our complete set of proven cybersecurity solutions. These
                  solutions help organizations detect, protect against, and
                  recover from attacks. We take care of your Cybersecurity needs
                  so you can focus on your business.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Why is cybersecurity important?
                </Accordion.Header>
                <Accordion.Body>
                  Cybersecurity is important because it helps protect
                  organizations and individuals from cyber threats that can have
                  serious consequences, including financial losses, reputational
                  damage, and legal liabilities.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What are the common cyber threats?
                </Accordion.Header>
                <Accordion.Body>
                  Common cyber threats include malware, phishing attacks,
                  ransomware, denial- of-service attacks, and insider threats.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  How can I protect my data from cyber attacks?
                </Accordion.Header>
                <Accordion.Body>
                  You can protect your data from cyber attacks by implementing
                  strong security measures, such as using antivirus software,
                  keeping your software up to date, and educating your employees
                  about cyber threats.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What should I look for in a cybersecurity service provider?
                </Accordion.Header>
                <Accordion.Body>
                  When choosing a cybersecurity service provider, you should
                  look for expertise, experience, and a proven track record of
                  delivering effective cybersecurity solutions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  How do I know if my systems have been compromised?
                </Accordion.Header>
                <Accordion.Body>
                  Signs that your systems may have been compromised include
                  unusual activity, such as unauthorized access attempts,
                  unexpected changes in system performance, and unexplained data
                  loss.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What should I do if I suspect a cyber attack?
                </Accordion.Header>
                <Accordion.Body>
                  If you suspect a cyber attack, you should immediately
                  disconnect the affected systems from the network, contact your
                  IT department or cybersecurity service provider, and follow
                  their instructions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  How often should I update my cybersecurity systems?
                </Accordion.Header>
                <Accordion.Body>
                  You should update your cybersecurity systems regularly to
                  ensure that they are protected against the latest cyber
                  threats.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  What is the difference between cybersecurity and data
                  security?
                </Accordion.Header>
                <Accordion.Body>
                  Cybersecurity focuses on protecting systems, networks, and
                  data from digital attacks, while data security focuses on
                  protecting the confidentiality, integrity, and availability of
                  data.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cyberfaq;
