import React from 'react'
import Modal from "react-bootstrap/Modal";

function Automatedetsting({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Automated Testing Services (a-ATS)</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <li className="rpafocusmodalpara">
          How to move from (structured) manual testing to structured automated
          testing?
        </li>
        
      </Modal>
    </>
  );
}

export default Automatedetsting;