import React from "react";
import call from "../../images/Call.svg";
import mess from "../../images/Message.svg";
import face from "../../images/Facebook.svg";
import link from "../../images/Linkedin.svg";

function Maincontact() {
  return (
    <>
      <section className="maincontact">
        <div className="container h-100">
          <div className="mainform">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h2>Contact !</h2>
                <form>
                  <div className="topinput d-flex">
                    <input type="text" placeholder="First Name"></input>
                    <input type="text" placeholder="Last Name"></input>
                  </div>
                  <div className="topinput d-flex">
                    <input type="text" placeholder="Business Email"></input>
                    <input type="text" placeholder="Business Phone"></input>
                  </div>
                  <div className="input">
                    <input type="text" placeholder="Company"></input>
                    <select>
                      <option value="Robotic Process Automation">
                        Robotic Process Automation
                      </option>
                      <option value="QA Automation">QA Automation</option>
                      <option value="Scrum Master">Scrum Master</option>
                      <option value="Staff Augmentation">
                        Staff Augmentation
                      </option>
                      <option value="Digital Transformation">
                        Digital Transformation
                      </option>
                    </select>
                  </div>
                  <div className="input">
                    <textarea placeholder="Message" rows={4}></textarea>
                  </div>
                  <button>SEND</button>
                </form>
              </div>
              <div className="col-12 col-lg-6 get">
                <h2>
                  Get in <br />
                  <span>Touch</span> with <span>Us!</span>
                </h2>
                <p>
                  Thank you for your interest in Agile Tech Labs. Please provide
                  your contact details and area of interest and someone will get
                  back to you as soon as possible.Whether you’re in the early
                  stages of product research, evaluating competitive solutions,
                  or just trying to scope your needs to begin a project, we’re
                  happy to help you get the information you need to move forward
                  with your IT initiatives.
                </p>
                <div className="address">
                  <div className="add">
                    <p>
                      Agile Tech Labs, <br />
                      440 Burroughs Street, <br />
                      Ste 616,Detroit, MI 48202
                    </p>
                  </div>
                  <div className="add">
                    <p>
                      Agile Tech Labs,26700 <br />
                      Lahser Rd, Suite # 350, <br />
                      Southfield, MI 48076
                    </p>
                  </div>
                </div>
                <div className="social">
                  <div className="social1">
                    <div className="soctxt">
                      <img src={mess} loading="lazy" alt="email" />
                      <p>Info@agile-techlabs.com</p>
                    </div>
                    <div className="soctxt">
                      <img src={call} loading="lazy" alt="phone" />
                      <p>(866)-996-4169</p>
                    </div>
                  </div>
                  <div className="social2 mt-3">
                    <div className="soctxt">
                      <img src={face} loading="lazy" alt="facebook" />
                      <p>Facebook</p>
                    </div>
                    <div className="soctxt">
                      <img src={link} loading="lazy" alt="linkdin" />
                      <p>Linkedin</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="copyright">
          © 2024 Agile Tech Labs. All Rights Reserved.
        </p>
      </section>
    </>
  );
}

export default Maincontact;
