import React from 'react'

function Rpabanner() {
    return (
        <>
            <section className='rpabanner'>
                {/* bg image CSS */}
            </section>
        </>
    )
}

export default Rpabanner;