import React from "react";
import Itstaffingbanner from "../It staffing/Itstaffingbanner";
import Whyitstaff from "../It staffing/Whyitstaff";
import Itstafffocus from "../It staffing/Itstafffocus";
import Itstaffchooseus from "../It staffing/Itstaffchooseus";
import Itfaq from "../It staffing/Itfaq";

import Focuscontact from "../RPA/Focuscontact";

function ItstaffRoute() {
  return (
    <>
      <Itstaffingbanner />
      <Whyitstaff />
      <Itstafffocus />
      <Itstaffchooseus />
      <Itfaq />
      <Focuscontact />
    </>
  );
}

export default ItstaffRoute;
