import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext } from "react";
import logo from "../../images/Logo-min.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import UserContext from "../../Context/UserContext";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter email address"),
  password: Yup.string().required("Please enter password"),
});

function Login() {
  const router = useNavigate();
  // const { setUser } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log(values);
      axios
        .post("http://agile.digiwbs.com/Admin/api/login", values)
        .then((result) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            text: "Login successful",
            showConfirmButton: false,
            timer: 1500,
            width: 300,
          });
          let userData = result.data.user;
          // setUser(userData);
          router("/admin/dashboard");
          localStorage.setItem("Login", JSON.stringify(userData));
          // console.log("login", userData);
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            text: "Login failed. Please check your credentials and try again.",
            showConfirmButton: false,
            timer: 1500,
            width: 300,
          });
          console.log(err);
        });
    },
  });

  return (
    <>
      <section className="login">
        <div className="container">
          <div className="loginform">
            <div className="loginlogo">
              <img src={logo} alt="Logo" />
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <h2>Login to your account</h2>
              </div>
              <div className="text-start">
                <label>Email</label>
                <br />
                <input
                  type="email"
                  placeholder="Email address"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                ></input>
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <br />
              <div className="text-start">
                <label>Password</label>
                <br />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                ></input>
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="d-flex justify-content-end">
                <Link to="/forget" className="forgetpass">
                  Forget Password
                </Link>
              </div>
              <div>
                <button type="submit">Login</button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
