import React from "react";
import Modal from "react-bootstrap/Modal";
function Assessment({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Assessment Services</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          The First Step in identifying areas of exposure includes a thorough
          security assessment. As part of the assessment, our team gives you a
          clear picture of how well your security controls and integrated
          defenses are performing and where are the gaps for a strong security
          posture.
        </p>
        <li>Attack Path Effectiveness Review</li>
        <li>Cloud Services Security Assessment</li>
        <li>Threat Vulnerability</li>
        <li>Web Application Security Assessment</li>
        <li>Network Architecture Security Review</li>
        <li>Penetration Testing</li>
        <li>Strong Password Audits</li>
        <li>Social Engineering Attack</li>
        <li>Mobile Application Security Assessment</li>
        <li>Purple Team – Adversarial Detection & Countermeasures</li>
        <li>Red Team – Adversarial Attack Simulation</li>
      </Modal>
    </>
  );
}

export default Assessment;
