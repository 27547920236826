import React, { useContext, useState } from "react";
import Editinfo from "../Profile/Editinfo";
import UserContext from "../../../Context/UserContext";

const imagePath =
  process.env.IMAGE_PATH || "https://agile.digiwbs.com/Admin/public/storage/";

function Profile() {
  const { user } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <>
      <div className="profile">
        <h2>Profile</h2>
        <div className="row mt-4">
          <div className="col-12 col-lg-4  pt-4">
            <div className="image-col">
              <img src={`${imagePath}${user?.image}`} />

              <h3>
                {user?.role === "superadmin"
                  ? "Super Admin"
                  : user?.role === "admin"
                  ? "Admin"
                  : user?.role === "employee"
                  ? "Employee"
                  : "Employee"}
              </h3>
            </div>
          </div>
          <div className="col-12 col-lg-8  pt-4">
            <div className="text-col">
              <div className="profile-form">
                <h3>User Name</h3>
                <p>{user.user_name}</p>
              </div>
              <div className="profile-form">
                <h3>Name</h3>
                <p>{user?.first_name + " " + user?.last_name}</p>
              </div>
              <div className="profile-form">
                <h3>Email</h3>
                <p>{user?.email}</p>
              </div>
              <div className="profile-form">
                <h3>Mobile</h3>
                <p>{user?.mobile}</p>
              </div>

              {/* <div className="profile-form">
                <h3>Permissions</h3>
                <p> {data?.permissions.map((val) => `${val}, `)}</p>
              </div> */}
              <button className="btn m-3" onClick={openModal}>
                Update Info
              </button>
            </div>
          </div>
        </div>
      </div>
      <Editinfo show={showModal} handleClose={closeModal} />
    </>
  );
}

export default Profile;
