import React from "react";
import { Accordion } from "react-bootstrap";

function Productfaq() {
  return (
    <>
      <section className="rpafaq pt-5 pb-5">
        <div className="container">
          <div className="row mt-5">
            <h2 className="mb-5">
              Frequently Asked{" "}
              <span style={{ color: "#003eae" }}>Questions</span> about Product
              Re-Engineering
            </h2>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What is product re-engineering?
                </Accordion.Header>
                <Accordion.Body>
                  Product re-engineering is the process of modernizing outdated
                  software or systems by updating them with newer technologies,
                  improving performance, enhancing security, and making them
                  more user-friendly. It involves assessing existing
                  applications, planning a migration strategy, and implementing
                  the necessary upgrades
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Why should I modernize my legacy applications?
                </Accordion.Header>
                <Accordion.Body>
                  Modernizing legacy applications helps improve their
                  performance, security, and user experience. It reduces
                  maintenance costs, eliminates outdated technology risks, and
                  ensures your systems are scalable and ready for future growth.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How does Agile Tech Labs approach product re-engineering?
                </Accordion.Header>
                <Accordion.Body>
                  At Agile Tech Labs, we start with a thorough assessment of
                  your existing systems. We then develop a tailored
                  re-engineering plan that aligns with your business goals. Our
                  team carefully migrates your applications to new technologies
                  and provides ongoing support to ensure a smooth transition and
                  long-term success.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Will modernizing my applications disrupt my business
                  operations?
                </Accordion.Header>
                <Accordion.Body>
                  We understand that minimizing disruption is crucial. Our team
                  at Agile Tech Labs uses a methodical approach, including
                  rigorous testing and quality checks, to ensure that the
                  modernization process has minimal impact on your day-to-day
                  operations.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  What technologies do you use for product re-engineering?
                </Accordion.Header>
                <Accordion.Body>
                  We work with a wide range of modern technologies, including
                  cloud platforms, advanced programming languages, updated
                  database management systems, and modern user interface
                  frameworks. The specific technologies used will depend on the
                  unique needs of your business.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How long does the re-engineering process take?
                </Accordion.Header>
                <Accordion.Body>
                  The timeline for product re-engineering depends on the
                  complexity and scope of the project. After assessing your
                  current systems, we provide a detailed timeline and work
                  closely with you to ensure the project is completed
                  efficiently.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  What are the costs involved in product re-engineering?
                </Accordion.Header>
                <Accordion.Body>
                  The cost varies based on the size and complexity of your
                  project. Agile Tech Labs offers cost-effective solutions by
                  leveraging our experience, reusable components, and
                  streamlined processes. We work with you to deliver the best
                  value within your budget.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  What types of legacy systems can Agile Tech Labs re-engineer?
                </Accordion.Header>
                <Accordion.Body>
                  We can re-engineer a wide range of legacy systems, including
                  outdated software applications, databases, and platforms. Our
                  team has experience with various technologies and industries,
                  so we can handle most legacy systems.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What happens after the re-engineering project is completed?
                </Accordion.Header>
                <Accordion.Body>
                  After the project is completed, Agile Tech Labs provides
                  ongoing support to ensure your newly modernized applications
                  run smoothly. We offer maintenance, updates, and assistance to
                  help you get the most out of your modernized systems.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  Can Agile Tech Labs handle large-scale modernization projects?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, Agile Tech Labs has extensive experience managing
                  large-scale modernization projects. Our team of experienced
                  architects, program managers, and business experts can handle
                  complex projects, delivering quality results on time and
                  within budget.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  How do I get started with product re-engineering at Agile Tech
                  Labs?
                </Accordion.Header>
                <Accordion.Body>
                  Getting started is easy. Simply contact us to schedule an
                  initial consultation. We’ll discuss your needs, assess your
                  current systems, and develop a customized plan to modernize
                  your applications and improve your business operations.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  What kind of support do you offer after the re-engineering is
                  complete?
                </Accordion.Header>
                <Accordion.Body>
                  We provide ongoing support to ensure your modernized
                  applications continue to run smoothly. Our team is available
                  to address any issues, make updates, and offer assistance as
                  needed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  Can you help with just part of the re-engineering process?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we can assist with specific aspects of the re-engineering
                  process, such as cloud migration, UI modernization, or
                  performance optimization. We tailor our services to meet your
                  specific needs.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  How do I get started with Agile Tech Labs for product
                  re-engineering?
                </Accordion.Header>
                <Accordion.Body>
                  Getting started is easy! Simply contact us to schedule an
                  initial consultation. We’ll discuss your needs, assess your
                  legacy system, and create a plan to modernize your
                  applications.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Productfaq;
