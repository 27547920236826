import React from "react";
import Productengineeringbanner from "../Product Re-engineering/Productengineeringbanner";
import Whatisproductengineering from "../Product Re-engineering/Whatisproductengineering";
import Focuscontact from "../RPA/Focuscontact";
import Productapproach from "../Product Re-engineering/Productapproach";
import Productfocus from "../Product Re-engineering/Productfocus";
import Productchooseus from "../Product Re-engineering/Productchooseus";
import Producthelp from "../Product Re-engineering/Producthelp";
import Productfaq from "../Product Re-engineering/Productfaq";

function ProductengineerRoute() {
  return (
    <>
      <Productengineeringbanner />
      <Whatisproductengineering />
      <Productapproach />
      <Productfocus />
      <Productchooseus />
      <Producthelp />
      <Productfaq />
      <Focuscontact />
    </>
  );
}

export default ProductengineerRoute;
