import React from "react";
import Solution from "../About/Solution";
import Solutionfocus from "../About/Solutionfocus";
import Aboutus from "../About/Aboutbanner";
import Contactabout from "../About/Contactabout";
import NavbarWhite from "../Home/NavbarWhite";
import Whoweare from "../About/Whoweare";
import Aboutchoose from "../About/Aboutchoose";

const Slider = () => {
  return (
    <>
      <NavbarWhite />
      <Aboutus />
      <Solution />
      <Whoweare/>
      <Solutionfocus />
      <Aboutchoose/>
      <Contactabout />
    </>
  );
};

export default Slider;
