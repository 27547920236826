import React from 'react'

function Whoweare() {
  return (
    <>
      <section className="pt-5 pb-5 whoare">
        <div className="container">
          <div className="row">
            <h2>WHO WE ARE</h2>
            <p>
              We are Agile Tech Labs, a forward-thinking technology consulting
              firm dedicated to delivering impactful solutions that transform
              organizations. Our mission is to make businesses more connected,
              automated, secure, and driven by data-driven insights. By
              constantly seeking innovative ways to amplify productivity, we
              help our clients achieve their goals more efficiently.
            </p>
            <br />
            <p>
              With a commitment to excellence and a focus on meaningful results,
              we partner with organizations to navigate the complexities of
              today's digital landscape. Whether it's enhancing operational
              efficiency, securing critical systems, or unlocking the power of
              data, we are here to guide you every step of the way
            </p>
            <br />
            <p>
              Let’s innovate together and shape the future of your organization.
            </p>
            <br />
            <br />
            <h2>OUR MISSION</h2>
            <p>
              Our mission at Agile Tech Labs is to empower organizations by
              delivering innovative, secure, and automated solutions that drive
              connectivity and actionable insights. We are committed to
              partnering with our customers to create technology that not only
              meets their needs today but also positions them for future
              success.
            </p>
            <br />
            <br />
            <h2>OUR VISION</h2>
            <p>
              Our vision at Agile Tech Labs is to be a global leader in
              technology consulting, driving the future of connected, automated,
              and secure organizations. We aspire to create innovative solutions
              that transform industries, empower businesses, and enhance the
              lives of people around the world through technology.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Whoweare