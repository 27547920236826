import React from "react";

function Solutionfocus() {
  return (
    <>
      <section className="solufocus pt-5 pb-5">
        <div className="container soluimg">
          <div className="soluhead">
            <h2>OUR VALUES</h2>
            <ol>
              <li>
                <strong>Innovation</strong> We continuously seek new ideas and
                embrace cutting-edge technologies to deliver solutions that
                drive progress and inspire change
              </li>
              <li>
                <strong>Integrity</strong> We conduct our business with honesty,
                transparency, and ethical principles, building trust with our
                clients, partners, and team members.
              </li>
              <li>
                <strong>Excellence</strong> We are committed to delivering
                high-quality solutions that exceed expectations and add real
                value to our clients' organizations.
              </li>
              <li>
                <strong>Collaboration</strong> We believe in the power of
                teamwork and foster a culture of collaboration, both within our
                company and with our clients, to achieve shared goals.
              </li>
              <li>
                <strong>Customer-Centricity</strong> Our customers are at the
                heart of everything we do. We listen, understand their needs,
                and tailor our solutions to help them succeed.
              </li>
              <li>
                <strong>Adaptability</strong> In a rapidly changing digital
                landscape, we remain flexible and responsive, ready to evolve
                and adapt our solutions to meet the emerging needs of our
                clients.
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default Solutionfocus;
