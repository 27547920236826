import React from "react";
import Modal from "react-bootstrap/Modal";
function Strategy({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Agile Transformation Strategy</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We create customized agile transformation strategies tailored to each
          client's unique needs and goals. From defining the scope of the
          transformation to setting objectives and creating a roadmap for
          implementation, we are here to guide you every step of the way.
        </p>
      </Modal>
    </>
  );
}

export default Strategy;
