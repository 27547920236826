import React, { useState } from "react";
import migrat from "../../images/Managed Cybersecurity Services.jpeg";
import integrat from "../../images/CyberSecurity Assessment v1.jpeg";
import develop from "../../images/Cybersecurity Staffing.jpeg";
import implement from "../../images/Cybersecurity Training.jpeg";
import Managed from "./focus areas/Managed";
import Assessment from "./focus areas/Assessment";
import Staffing from "./focus areas/Staffing";
import Ongoing from "./focus areas/Ongoing";

function Cyberfocus() {
  const [managedModal, setManagedModal] = useState(false);
  const [assessmentModal, setAssessmentModal] = useState(false);
  const [staffingModal, setStaffingModal] = useState(false);
  const [ongoingModal, setOngoingModal] = useState(false);

  const openModal = () => setManagedModal(true);
  const closeModal = () => setManagedModal(false);

  const assessmentShow = () => setAssessmentModal(true);
  const closeassessment = () => setAssessmentModal(false);

  const staffingShow = () => setStaffingModal(true);
  const closestaffing = () => setStaffingModal(false);

  const ongoingShow = () => setOngoingModal(true);
  const closeongoing = () => setOngoingModal(false);
  return (
    <>
      <section className="mtsfocus">
        <div className="container">
          <h2>Focus Areas of Cybersecurity</h2>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 " onClick={openModal}>
                {/* <Link to="/rpa"> */}
                <div className="btnimg">
                  <img src={migrat} loading="lazy" alt="migrat" />
                  <div className="mtsfocustxt">
                    <p>Managed Cybersecurity Services</p>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-12 col-lg-4" onClick={assessmentShow}>
                <div className="btnimg">
                  <img src={integrat} loading="lazy" alt="integrat" />
                  <div className="mtsfocustxt">
                    <p>Assessment Services</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={staffingShow}>
                <div className="btnimg">
                  <img src={develop} loading="lazy" alt="develop" />
                  <div className="mtsfocustxt">
                    <p>Cyber Security Staffing</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={ongoingShow}>
                <div className="btnimg">
                  <img src={implement} loading="lazy" alt="implement" />
                  <div className="mtsfocustxt">
                    <p>Professional Cyber Security On-Going Training</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Managed show={managedModal} handleClose={closeModal} />
      <Assessment show={assessmentModal} handleClose={closeassessment} />
      <Staffing show={staffingModal} handleClose={closestaffing} />
      <Ongoing show={ongoingModal} handleClose={closeongoing} />
    </>
  );
}

export default Cyberfocus;
