import React from "react";
import Modal from "react-bootstrap/Modal";
function Legacysystem({ show, handleClose }) {

  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Legacy System Assessment</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We start by evaluating your current systems to understand their
          strengths, weaknesses, and areas that need improvement. <br/>This helps us
          create a solid plan for modernization.
        </p>
      </Modal>
    </>
  );
}

export default Legacysystem;
