import React from 'react'
import video from "../../Videos/openshotprojectnew14-02-24.mp4";
import { Link } from 'react-router-dom'

function Inovation() {
  return (
    <>
      <section className="ino">
        <video autoPlay loop muted id="myVideo">
          <source src={video} type="video/mp4" loading="lazy" />
        </video>
        <div className="container ino-container">
          <div className="heading">
            <h1>Innovation and Technology</h1>
            <p>Focused On Your Success</p>
          </div>
          <div className="btngroup d-flex">
            <button className="learn">Learn More</button>
            <Link to="/contact">
              <button className="contact">Contact</button>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}

export default Inovation