import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";


const imagePath =
  process.env.IMAGE_PATH || "https://agile.digiwbs.com/Admin/public/storage/";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address"),
  password: Yup.string().min(8, "Must be 8 characters or greater"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function EditUserModal({ show, handleClose, userId }) {
  const modalStyles = {
    display: show ? "block" : "none",
  };

  // console.log("check user id", userId);

  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState([]);  
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (userId) {
      axios
        .get(`https://agile.digiwbs.com/Admin/api/users/${userId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setData(result.data);
          formik.setValues(result.data);

          // Check if the data.image exists and update preview
          if (result.data.image) {
            setImagePreview(`${imagePath}${result.data.image}`);
          } else {
            setImagePreview(null); // Clear preview if no image
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    const [role, permission] = name.split("-");

    setPermissions((prevPermissions) => {
      if (checked) {
        return [...new Set([...prevPermissions, permission])];
      } else {
        return [...new Set(prevPermissions.filter((p) => p !== permission))];
      }
    });

    formik.setValues({ ...formik.values, role: role });
    // formik.setValues({ ...formik.values });
  };

  const getPermissionsForRole = (role) => {
    return permissions
      .filter((perm) => perm.startsWith(role))
      .map((perm) => perm.replace(`${role}-`, ""));
  };

  const formik = useFormik({
    initialValues: {
      id: data.id,
      user_name: data?.user_name || "",
      first_name: data?.first_name || "",
      last_name: data?.last_name || "",
      email: data?.email || "",
      password: "",
      mobile: data?.mobile || "",
      userName: data?.userName || "",
      role: data?.role || "",
      image: data?.image || "",
    },
    validationSchema,
    onSubmit: (values) => {
      const selectedRole = values.role;
      const permissions = getPermissionsForRole(selectedRole);

      axios
        .put(
          `https://agile.digiwbs.com/Admin/api/users/${userId}`,
          { ...values, permissions },
          // { ...values },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response?.data?.message || "Update failed",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  });

   const handleImageChange = (event) => {
     const file = event.currentTarget.files[0];
     if (file) {
       formik.setFieldValue("image", file);
       setImagePreview(URL.createObjectURL(file)); // Create a preview URL
     } else {
       setImagePreview(null); // Clear the preview if no file is selected
     }
   };

  return (
    <>
      <div className="modal" style={modalStyles}>
        <div className="modal-content">
          <div className="modal-head">
            <h2>Edit User</h2>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
          </div>
          <hr />
          <form onSubmit={formik.handleSubmit}>
            <label for="upload-pic" className="profile-pic">
              <input
                type="file"
                accept="\image*"
                id="upload-pic"
                hidden
                onChange={handleImageChange}
              />
              <div className="upload-img">
                <img
                  src={imagePreview || `${imagePath}${data.image}`} // Use imagePreview if available
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </label>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="First Name*"
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
              </div>
              <div className="err">
                <input
                  type="text"
                  placeholder="Last Name*"
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="User Name*"
                  className="userid"
                  name="user_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.user_name}
                />
              </div>
              <div className="err">
                <input
                  type="email"
                  placeholder="Email*"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="PhoneNo*"
                  name="mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
              </div>
              <div className="err">
                <select
                  name="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                >
                  <option>Select Role Type</option>
                  <option value={"superadmin"}>Super Admin</option>
                  <option value={"admin"}>Admin</option>
                  <option value={"employee"}>Employee</option>
                </select>
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="password"
                  placeholder="Password*"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </div>
              <div className="err">
                <input
                  type="password"
                  placeholder="Confirm Password*"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
              </div>
            </div>
            <table className="per-table">
              <thead>
                <tr>
                  <th className="modal-cate">Module Permission</th>
                  <th>Read</th>
                  <th>Write</th>
                  <th className="modal-dlt">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="modal-cate">Super Admin</td>
                  <td className="modal-read model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "superadmin" &&
                        permissions.includes("read")
                      }
                      name="superadmin-read"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "superadmin" &&
                        permissions.includes("write")
                      }
                      name="superadmin-write"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="modal-dlt model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "superadmin" &&
                        permissions.includes("execute")
                      }
                      name="superadmin-execute"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="modal-cate">Admin</td>
                  <td className="modal-read model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "admin" &&
                        permissions.includes("read")
                      }
                      name="admin-read"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "admin" &&
                        permissions.includes("write")
                      }
                      name="admin-write"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="modal-dlt model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "admin" &&
                        permissions.includes("execute")
                      }
                      name="admin-execute"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="modal-cate">Employee</td>
                  <td className="modal-read model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "employee" &&
                        permissions.includes("read")
                      }
                      name="employee-read"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "employee" &&
                        permissions.includes("write")
                      }
                      name="employee-write"
                      onChange={handleChange}
                    />
                  </td>
                  <td className="modal-dlt model-td">
                    <input
                      type="checkbox"
                      checked={
                        formik.values.role === "employee" &&
                        permissions.includes("execute")
                      }
                      name="employee-execute"
                      onChange={handleChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="tablebtn">
              <button className="addbtn" type="submit">
                Update User
              </button>
              <Link className="cnclbtn" onClick={handleClose}>
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditUserModal;
