import React from 'react'
import Transformbanner from '../Agile Transformation/Transformbanner'
import Whatistransform from '../Agile Transformation/Whatistransform'
import Transformbenifit from '../Agile Transformation/Transformbenifit'
import TransformFocus from '../Agile Transformation/TransformFocus'
import TransformChooseus from '../Agile Transformation/TransformChooseus'
import TransformFaq from '../Agile Transformation/TransformFaq'
import TransformAproach from '../Agile Transformation/TransformAproach'
import Focuscontact from '../RPA/Focuscontact'

function AgiletransformationRoute() {
  return (
      <>
      <Transformbanner />
      <Whatistransform />
      <Transformbenifit />
      <TransformFocus />
      <TransformAproach/>
      <TransformChooseus />
      <TransformFaq />
      <Focuscontact/>
      </>
  )
}

export default AgiletransformationRoute