import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const Login = localStorage.getItem("Login");

  if (!Login) {
    // If no token, redirect to login
    return <Navigate to="/login" />;
  }

  // If authenticated, render the children components (e.g., dashboard)
  return children;
};

export default ProtectedRoute;
