import React from "react";

function Producthelp() {
  return (
    <>
      <section className="pt-5  pb-5">
        <div className="container">
          <div className="row">
            <h2>Agile Tech can help</h2>
            <li>
              Design, develop and prototype a new system solution that would
              address challenges with the existing device solution.
            </li>
            <li>
              Develop and deliver tailored end-to-end solution that includes
              ideation to prototype, hardware and software development, and
              validation and verification.
            </li>
            <li>
              Implement innovative architecture, and futuristic frameworks that
              ensure scalability and extendibility to newer technologies.
            </li>
            <li>
              Right solution ensures that the products and services conformed to
              the highest standards of reliability in design & production.
            </li>
            <li>
              Our experts adapts the accelerated Agile Iterative Methodology to
              evaluate the key steps and processes to implement the solution
              within stringent timelines and ensure first time pass assurance.
            </li>
          </div>
        </div>
      </section>
    </>
  );
}

export default Producthelp;
