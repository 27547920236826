import React from 'react'
import Modal from "react-bootstrap/Modal";

function Cunsulting({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Testing Consulting services (a-TCS)</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <li className="rpafocusmodalpara">
          A model for the improvement of testing that offers insight into the
          maturity’ of the current test process and improvement actions to
          accomplish the desired test maturity level
        </li>
        <p>
          A structured test process aimed at early detection of defects in a
          cost effective manner resulting in clear insight into software Quality
        </p>
      </Modal>
    </>
  );
}

export default Cunsulting