import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import UserContext from "../../../Context/UserContext";

const imagePath =
  process.env.IMAGE_PATH || "https://agile.digiwbs.com/Admin/public/storage/";

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address"),
  password: Yup.string().min(8, "Must be 8 characters or greater"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

function Setting({ show, handleClose }) {
  const [imagePreview, setImagePreview] = useState(null);
  const modalStyles = {
    display: show ? "block" : "none",
  };

  const { user, updateUser } = useContext(UserContext);

  const formik = useFormik({
    
    initialValues: {
      user_name: user?.user_name || "",
      first_name: user?.first_name || "",
      last_name: user?.last_name || "",
      email: user?.email || "",
      mobile: user?.mobile || "",
      role: user?.role || "",
      password: "",
      image: user?.image || "",
    },

    validationSchema,
    onSubmit: (values) => {
      const selectedRole = values.role;

      axios
        .put(
          `https://agile.digiwbs.com/Admin/api/users/${user.id}`, // Use the correct user ID
          { ...values, role: selectedRole },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          updateUser(response.data);
          console.log("", response.data); // Update the user context with the new data
          handleClose();
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: err.response?.data?.message || "Update failed",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    },
  });

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("image", file);
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL
    } else {
      setImagePreview(null); // Clear the preview if no file is selected
    }
  };

  return (
    <>
      <div className="modal" style={modalStyles}>
        <div className="modal-content">
          <div className="modal-head">
            <h2>Edit User</h2>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
          </div>
          <hr />
          <form onSubmit={formik.handleSubmit}>
            <label for="upload-info" className="profile-pic">
              <input
                type="file"
                accept="\image*"
                id="upload-info"
                hidden
                onChange={handleImageChange}
              />
              <div className="upload-pic">
                <img
                  src={imagePreview || `${imagePath}${user.image}`} // Use imagePreview if available
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </label>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="First Name*"
                  name="first_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
              </div>
              <div className="err">
                <input
                  type="text"
                  placeholder="Last Name*"
                  name="last_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="User Name*"
                  className="userid"
                  name="user_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.user_name}
                />
              </div>
              <div className="err">
                <input
                  type="email"
                  placeholder="Email*"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="PhoneNo*"
                  name="mobile"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                />
              </div>
              <div className="err">
                <select
                  name="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                >
                  <option>Select Role Type</option>
                  <option value={"superadmin"}>Super Admin</option>
                  <option value={"admin"}>Admin</option>
                  <option value={"employee"}>Employee</option>
                </select>
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="password"
                  placeholder="Password*"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
              </div>
              <div className="err">
                <input
                  type="password"
                  placeholder="Confirm Password*"
                  name="confirmPassword"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
              </div>
            </div>
            <div className="tablebtn">
              <button className="addbtn" type="submit">
                Update User
              </button>
              <Link className="cnclbtn" onClick={handleClose}>
                Cancel
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Setting;
