import React from "react";

function Productapproach() {
  return (
    <>
      <section className="mtsbenifit pb-5">
        <div className="container">
          <h2>Our Approach</h2>
          <h4 className="mt-3">Assessment</h4>
          <li>
            <strong>Assessment: </strong> We start by analyzing your existing
            systems to understand their strengths and weaknesses.
          </li>
          <li>
            <strong>Planning: </strong> Our experts design a custom migration
            strategy that aligns with your business goals.
          </li>
          <li>
            <strong>Execution: </strong>We carefully migrate your applications
            to new technologies with minimal disruption to your operations.
          </li>
          <li>
            <strong>Support: </strong> After the migration, we offer ongoing
            support to ensure your new system runs smoothly.
          </li>

          <br />
          <p>
            At Agile Tech Labs, we’re committed to delivering solutions that not
            only meet today’s needs but also set you up for future success. Let
            us help you transform your legacy applications into modern,
            high-performing assets for your business.
          </p>
        </div>
      </section>
    </>
  );
}

export default Productapproach;
