import React from "react";
import Inotech from "./Inotech";
import { Link } from "react-router-dom";

function Digital() {
  return (
    <>
      <section className="digi ino">
        <div className="container digihead ino-container">
          <div className="heading">
            <h1>Digital Transformation</h1>
            <Inotech />
          </div>
          <div className="btngroup d-flex">
            <button className="learn">Learn More</button>
            <Link to="/contact">
              <button className="contact">Contact</button>
            </Link>
          </div>
        </div>
        <p className="about-digi">
          © 2024 Agile Tech Labs. All Rights Reserved.
        </p>
      </section>
    </>
  );
}

export default Digital;
