import React from 'react'
import Modal from "react-bootstrap/Modal";
function Enablement({ show, handleClose }) {
   
    return (
      <>
       <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
            <div className="rpafocusmodalhead">
              <h2>Intelligent Automation enablement</h2>
              <span className="close" onClick={handleClose}>
                &times;
              </span>
            </div>
            <p className="rpafocusmodalpara">
              With years of experience in various IT technologies and
              Automation, it's crucial to align it with your processes and IT
              infrastructure. Our approach involves a meticulous evaluation of
              options like Robotic Process Automation (RPA), Business Process
              Management (BPM) software, and Artificial Intelligence (AI)
              technologies. By conducting a thorough analysis, we ensure that
              the chosen technology fits the unique requirements of your
              organization. We advocate for a phased implementation strategy,
              beginning with pilot projects to assess feasibility and
              scalability before full deployment. This ensures that automation
              solutions are tailored to your needs and deliver tangible benefits
              from the outset.
            </p>
            <br />

            <ol>
              <li>
                Thorough evaluation of automation technology options (RPA, BPM,
                AI)
              </li>
              <li>
                Alignment with organization's processes and IT infrastructure
              </li>
              <li>
                Phased implementation strategy, starting with pilot projects
              </li>
              <li>
                Ensuring feasibility and scalability before full deployment
              </li>
          </ol>
          </Modal>
      </>
    );
}

export default Enablement