import React from "react";
import Modal from "react-bootstrap/Modal";
function Automation({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Customer Service Automation</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We specialize in delivering customized solutions tailored to address
          specific customer service needs. By leveraging RPA technology, we
          automate processes such as inquiries handling, order processing, and
          complaint resolution, thereby enhancing efficiency and customer
          satisfaction. Our RPA bots are designed to seamlessly integrate with
          various communication channels, including chatbots, email, and voice
          assistants, ensuring consistent and efficient customer interactions
          across multiple touchpoints. This holistic approach enables
          organizations to streamline customer service operations, improve
          response times, and deliver a superior customer experience.
        </p>
        <br />

        <ol>
          <li>
            Specialization in providing customized RPA solutions for customer
            service needs
          </li>
          <li>
            Automation of inquiries handling, order processing, and complaint
            resolution
          </li>
          <li>
            Integration of RPA bots with various communication channels for
            consistent customer interactions
          </li>
          <li>
            Focus on enhancing efficiency and customer satisfaction through
            automation
          </li>
        </ol>
      </Modal>
    </>
  );
}

export default Automation;
