import React from "react";
import Modal from "react-bootstrap/Modal";

function Coe({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className=" rpafocusmodalhead">
          <h2>COE and Governance Setup</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          Pioneers in setting up right COE team for our clients tailoring to
          their needs and experts in the implementation of governance frameworks
          and controls to ensure compliance with regulatory requirements,
          industry standards, and internal policies. Establish roles and
          responsibilities for managing and maintaining automation assets,
          including bot governance, access controls, and audit trails.
        </p>
        <br />

        <ol>
          <li>Automated Process Monitoring</li>
          <li>Independent quality control reviews</li>
          <li>Support deployment of code to production</li>
          <li>Administration</li>
          <li>Development Life Cycle</li>
        </ol>
      </Modal>
    </>
  );
}

export default Coe;
