import React from "react";
import Modal from "react-bootstrap/Modal";
function Regulatory({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Regulatory Compliance</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          Proactively manage organizational change and engage stakeholders
          throughout the automation journey. Communicate the benefits of
          automation to employees and address concerns about job displacement or
          role changes. Provide training and support to ensure that employees
          are equipped with the necessary skills to work alongside automated
          processes effectively. Foster a culture of continuous improvement and
          innovation to drive successful automation outcomes.
        </p>
        <br />

        <ol>
          <li>
            Provide role-based, centralized data views and dynamic reporting
            tools.
          </li>
          <li>Reduce the chance of data errors</li>
          <li>Eliminate unauthorized access to privileged data</li>
          <li>Create an audit trail to review and monitor user activity</li>
        </ol>
      </Modal>
    </>
  );
}

export default Regulatory;
