import React from "react";
import { Accordion } from "react-bootstrap";

function Itfaq() {
  return (
    <>
      <section className="rpafaq pt-5 pb-5">
        <div className="container">
          <div className="row mt-5">
            <h2 className="mb-5">
              Frequently Asked{" "}
              <span style={{ color: "#003eae" }}>Questions</span> about IT
              Staffing
            </h2>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What types of IT staffing solutions does Agile Tech Labs
                  offer?
                </Accordion.Header>
                <Accordion.Body>
                  Agile Tech Labs offers a range of IT staffing solutions,
                  including contract staffing, contract-to-hire, and permanent
                  placement. We also provide specialized staffing services for
                  niche technologies and industry-specific requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How does Agile Tech Labs ensure that candidates are a good fit
                  for my organization?
                </Accordion.Header>
                <Accordion.Body>
                  We take a comprehensive approach to understanding your
                  business needs and organizational culture. Our recruitment
                  process involves detailed consultations with your team,
                  followed by rigorous screening and assessment of candidates to
                  ensure they meet both technical and cultural fit requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  How quickly can Agile Tech Labs fill a staffing request?
                </Accordion.Header>
                <Accordion.Body>
                  Our streamlined recruitment process allows us to quickly
                  identify and present qualified candidates. While the exact
                  timeframe can vary depending on the specific role and
                  requirements, we prioritize efficiency and aim to meet your
                  staffing needs in a timely manner.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  What industries and technologies do you specialize in?
                </Accordion.Header>
                <Accordion.Body>
                  Agile Tech Labs specializes in a wide range of industries and
                  technologies, including but not limited to software
                  development, data analytics, cloud computing, cybersecurity,
                  and more. We have experience in providing IT staffing
                  solutions across various sectors.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Can Agile Tech Labs provide staffing solutions for both
                  short-term and long-term projects?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we offer flexible staffing solutions to accommodate both
                  short-term and long-term project needs. Whether you need
                  temporary support for a specific project or permanent hires
                  for ongoing roles, we can tailor our services to meet your
                  requirements.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  What is the process for engaging with Agile Tech Labs for IT
                  staffing?
                </Accordion.Header>
                <Accordion.Body>
                  To engage with us, simply contact our team to discuss your
                  staffing needs. We will conduct a thorough consultation to
                  understand your requirements, followed by candidate sourcing,
                  screening, and presentation. Once you select a candidate, we
                  facilitate the onboarding process and provide ongoing support
                  as needed.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Are there any guarantees or warranties associated with your
                  staffing services?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we offer satisfaction guarantees on our staffing
                  services. If a candidate does not meet your expectations
                  within a specified period, we will work with you to find a
                  suitable replacement at no additional cost.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  How does Agile Tech Labs stay updated on industry trends and
                  technology advancements?
                </Accordion.Header>
                <Accordion.Body>
                  We continuously invest in industry research, professional
                  development, and technology training to stay current with the
                  latest trends and advancements. Our team regularly
                  participates in industry events and maintains a network of
                  industry experts to ensure we provide the most relevant and
                  up-to-date staffing solutions.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What makes Agile Tech Labs different from other IT staffing
                  providers?
                </Accordion.Header>
                <Accordion.Body>
                  Agile Tech Labs stands out due to our deep industry expertise,
                  personalized service, and commitment to quality. Our extensive
                  network, tailored recruitment approach, and flexible staffing
                  solutions set us apart, ensuring we meet your unique needs
                  effectively.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  How can I get in touch with Agile Tech Labs for IT staffing
                  inquiries?
                </Accordion.Header>
                <Accordion.Body>
                  You can reach out to us via our contact form on the website,
                  by phone, or by email. Our team is ready to assist you with
                  any inquiries and provide the support you need for your IT
                  staffing requirements.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
}

export default Itfaq;
