import React from "react";
import Modal from "react-bootstrap/Modal";
function Agileprocess({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Agile Process Improvement</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We work with organizations to enhance their existing agile processes,
          making them more effective and efficient through workflow refinement,
          team structure optimization, and the implementation of new tools and
          technologies.
        </p>
      </Modal>
    </>
  );
}

export default Agileprocess;
