import React from "react";

import Modal from "react-bootstrap/Modal";
function Migrat({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>RPA Managed Services</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          As an RPA firm we excel in delivering comprehensive automation
          solutions tailored to our clients' unique needs and challenges.
          Leveraging cutting-edge RPA technologies and Gen AI tools, we
          orchestrate end-to-end process automation, enabling seamless
          integration between digital and human workflows. Our team of seasoned
          consultants, industry experts, and experienced software developers
          collaborate closely with clients to design, develop, and maintain
          software bots that drive operational efficiency and productivity. From
          initial conceptualization to full-scale implementation, we guide our
          clients through every step of the automation journey, ensuring a
          smooth transition and successful outcomes. Our capabilities extend
          beyond mere implementation, as we offer ongoing support, monitoring,
          and optimization services to maximize the value of RPA investments
          over time.
        </p>
        <br />

        <ol>
          <li>End-to-end process automation leveraging RPA and Gen AI tools</li>
          <li>
            Collaboration with seasoned consultants, industry experts, and
            experienced software developers
          </li>
          <li>Seamless integration between digital and human workflows</li>
          <li>Ongoing support, monitoring, and optimization services</li>
        </ol>
      </Modal>
    </>
  );
}

export default Migrat;
