import React from 'react'

function Aboutbanner() {
    return (
        <>
            <section className='about'>
                <div className='aboutxt'>
                    <h1>About Us</h1>                    
                </div>
            </section>
        </>
    )
}

export default Aboutbanner;