import React from 'react'

function Inotech() {
    return (
        <>
            <div className='inotech'>
                <h4>Innovation and Technology</h4>
                <p>Focused On Your Success</p>
            </div>
        </>
    )
}

export default Inotech