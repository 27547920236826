import React from "react";
import Modal from "react-bootstrap/Modal";
function Userinterface({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>User Interface (UI) Modernization</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We update the look and feel of your applications to make them more
          user-friendly and aligned with modern design standards.
        </p>
      </Modal>
    </>
  );
}

export default Userinterface;
