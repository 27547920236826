import React from "react";
import Modal from "react-bootstrap/Modal";
function Report({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className=" rpafocusmodalhead">
          <h2>Report Automation</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          We are experts in harnessing the power of AI to generate customized
          reports tailored to the unique needs of business teams. These reports
          are automatically sent to stakeholders and C-suite leaders on a
          scheduled basis or upon demand, ensuring timely and relevant
          information delivery. Our bots are equipped with the capability to
          seamlessly pull together required data from various systems according
          to predefined schedules, streamlining the data collection process. By
          consolidating and organizing collected data, our solutions facilitate
          further analysis, empowering organizations to make data-driven
          decisions efficiently and effectively.
        </p>
        <br />

        <ol>
          <li>Specialization in Report Automation leveraging AI technology</li>
          <li>Customized reports tailored to the needs of business teams</li>
          <li>
            Automated delivery of reports to stakeholders and C-suite leaders
          </li>
          <li>
            Bots capable of pulling data from multiple systems on schedule for
            analysis
          </li>
        </ol>
      </Modal>
    </>
  );
}

export default Report;
