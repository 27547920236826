import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
// import { getToken } from "../../../utils/helper";

const baseUrl = process.env.REACT_APP_BASE_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;

const validationSchema = Yup.object({
  name: Yup.string().required("Please enter Title"),
  content: Yup.string()
    .min(6, "Must be 6 characters or greater")
    .required("Please enter content"),
  learn: Yup.string().required("Please enter learn link"),
  contect: Yup.string().required("Please enter contect link"),
  image: Yup.mixed(), // Do not require an image during editing
});

function EditSectionModal({ show, handleClose, fetchSectionData, editData }) {
  // let token = getToken();
  const modalStyles = {
    display: show ? "block" : "none",
  };

  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState("");
  const [data, setData] = useState(editData);
  const formik = useFormik({
    initialValues: {
      _id: data?._id || "",
      name: data?.name || "",
      content: data?.content || "",
      contect: data?.links?.find((link) => link.name === "Contect")?.link || "",
      image: null,
      learn: data?.links?.find((link) => link.name === "Learn")?.link || "",
    },
    validationSchema,
    onSubmit: (values) => {
      values.pageId = id;
      const formData = new FormData();
      formData.append("_id", values._id);
      formData.append("name", values.name);
      formData.append("content", values.content);
      formData.append("learn", values.learn);
      formData.append("contect", values.contect);
      formData.append("image", values.image);
      //   Use axios to send the form data
      axios
        .post(`${baseUrl}updateSection`, values, {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          formik.resetForm();
          handleClose();
          fetchSectionData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {
    setData(editData);

    if (editData?.links) {
      formik.setValues({
        _id: editData?._id || "",
        name: editData?.name || "",
        content: editData?.content || "",
        learn: editData.links.find((link) => link.name === "Learn")?.link || "",
        contect:
          editData.links.find((link) => link.name === "Contect")?.link || "",
      });
    }

    // Use FileReader to display a preview of the selected image
    if (editData?.image instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(editData?.image);
    } else {
      // If there is no existing image, reset the preview
      setImagePreview("");
    }
  }, [editData]);

  const handleFileChange = (event) => {
    try {
      const file = event.target.files[0];
      formik.setFieldValue("image", file);

      // Use FileReader to display a preview of the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setData((prevData) => ({
          ...prevData,
          imagePreview: reader.result,
        }));
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        setData((prevData) => ({
          ...prevData,
          imagePreview: null,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscard = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <>
      <div className="modal service-modal" style={modalStyles}>
        <div className="modal-content">
          <div className="service-head">
            <h2>Edit Section</h2>
          </div>
          <hr />
          <form>
            <div>
              <label for="upload" className="upload">
                <input
                  type="file"
                  accept="\image*"
                  id="upload"
                  hidden
                  onChange={handleFileChange}
                />
                <div className="upload-img">
                  <img
                    src={data?.imagePreview || `${imgUrl}${data?.image}`}
                    alt="Section"
                  />
                </div>
              </label>
            </div>
            <div>
              <input
                type="text"
                placeholder="Title"
                alt="Title"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </div>
            <div>
              <input
                type="text"
                placeholder="Description"
                alt="description"
                name="content"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.content}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="error">{formik.errors.content}</div>
              ) : null}
            </div>
            <div className="d-flex gap-3">
              <input
                type="text"
                placeholder="Learn"
                alt="Learn"
                name="learn"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.learn}
              />
              {formik.touched.learn && formik.errors.learn ? (
                <div className="error">{formik.errors.learn}</div>
              ) : null}
              <input
                type="text"
                placeholder="Contact"
                alt="Contact"
                name="contect"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contect}
              />
              {formik.touched.contect && formik.errors.contect ? (
                <div className="error">{formik.errors.contect}</div>
              ) : null}
            </div>
            <div className="tablebtn mt-4">
              <Link className="cnclbtn" onClick={handleDiscard}>
                Discard
              </Link>
              <Link className="addbtn" onClick={formik.handleSubmit}>
                Save
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EditSectionModal;
