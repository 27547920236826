import React from "react";
import leftimg from "../../images/Rpabanner__5__1-removebg-preview.png";

function Automationposter() {
  return (
    <>
      <section className="automationposter">
        <div className="container">
          <div className="automation-content">
            <h2>Thinking About Automation?</h2>
            <p>
              Let us assess your business and jointly unleash it's automation
              potential.
            </p>
            <button>Connect For Free Consultation</button>
            <img src={leftimg} alt="lftimg"/>
          </div>
        </div>
      </section>
    </>
  );
}

export default Automationposter;
