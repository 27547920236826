import React from "react";

function Cyberbanner() {
  return (
    <>
      <section className="cyberbanner"></section>
    </>
  );
}

export default Cyberbanner;
