import React, { useState } from "react";
import migrat from "../../images/Contract Staffing.jpeg";
import integrat from "../../images/Contract to Hire.jpeg";
import develop from "../../images/Permanent Staffing.jpeg";
import implement from "../../images/Specialized Staffing.jpeg";
import Contract from "./focus areas/Contract";
import Hire from "./focus areas/Hire";
import Placement from './focus areas/Placement'
import Specialize from "./focus areas/Specialize";

function Itstafffocus() {
  const [contractModal, setContractModal] = useState(false);
  const [hireModal, setHireModal] = useState(false);
  const [placementModal, setPlacementModal] = useState(false);
  const [specializeModal, setSpecializeModal] = useState(false);

  const openModal = () => setContractModal(true);
  const closeModal = () => setContractModal(false);

  const hireShow = () => setHireModal(true);
  const closehire = () => setHireModal(false);

  const placementShow = () => setPlacementModal(true);
  const closeplacement = () => setPlacementModal(false);

  const specializeShow = () => setSpecializeModal(true);
  const closespecialize = () => setSpecializeModal(false);
  return (
    <>
      <section className="mtsfocus">
        <div className="container">
          <h2>Focus Areas of Staffing Services</h2>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 " onClick={openModal}>
                <div className="btnimg">
                  <img src={migrat} loading="lazy" alt="migrat" />
                  <div className="mtsfocustxt">
                    <p>Contract Staffing</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={hireShow}>
                <div className="btnimg">
                  <img src={integrat} loading="lazy" alt="integrat" />
                  <div className="mtsfocustxt">
                    <p>Contract-to-Hire</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={placementShow}>
                <div className="btnimg">
                  <img src={develop} loading="lazy" alt="develop" />
                  <div className="mtsfocustxt">
                    <p>Permanent Placement</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={specializeShow}>
                <div className="btnimg">
                  <img src={implement} loading="lazy" alt="implement" />
                  <div className="mtsfocustxt">
                    <p>Specialized Staffing</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contract show={contractModal} handleClose={closeModal} />
      <Hire show={hireModal} handleClose={closehire} />
      <Placement show={placementModal} handleClose={closeplacement} />
      <Specialize show={specializeModal} handleClose={closespecialize} />
    </>
  );
}

export default Itstafffocus;
