import React from "react";
import Modal from "react-bootstrap/Modal";
function Project({ show, handleClose }) {
 
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Agile Project Management</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p className="rpafocusmodalpara">
          Agile Tech Labs specializes in agile project management, offering
          expert guidance to organizations looking to plan, execute, and track
          agile projects with precision. Our services encompass managing
          requirements, prioritizing tasks, and seamlessly adapting to evolving
          circumstances. Let us help you achieve your project goals with agility
          and efficiency!
        </p>
      </Modal>
    </>
  );
}

export default Project;
