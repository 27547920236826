import React from "react";

function Contactabout() {
  return (
    <>
      <section className="aboutcontacts">
        <div className="container h-100">
          <div className="contactform">
            <h2>Contact Us</h2>
            <div className="contactgap">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
              <input type="text" placeholder="Business Email" />
            {/* </div>
            <div className="contactgap"> */}
              <input type="text" placeholder="Company" />
              <input type="text" placeholder="Business Phone" />
              <select>
                <option>Service</option>
                <option value="Robotic Process Automation">
                  Robotic Process Automation
                </option>
                <option value="QA Automation">QA Automation</option>
                <option value="Scrum Master">Scrum Master</option>
                <option value="Staff Augmentation">Staff Augmentation</option>
                <option value="Digital Transformation">
                  Digital Transformation
                </option>
              </select>
            </div>
            <div className="send">
              <textarea cols={6} rows={3} placeholder="Message" />
              <button>SEND</button>
            </div>
          </div>
        </div>
        <p className="about-copy">
          © 2024 Agile Tech Labs. All Rights Reserved.
        </p>
      </section>
    </>
  );
}

export default Contactabout;
