import React from 'react'
import Inotech from './Inotech'
import { Link } from 'react-router-dom'

function Scrum() {
    return (
      <>
        <section className="scrum ino">
          <div className="container  ino-container">
            <div className="heading">
              <h1>Scrum Master</h1>
              <Inotech />
            </div>

            <div className="btngroup d-flex">
              <button className="learn">Learn More</button>
              <Link to="/contact">
                <button className="contact">Contact</button>
              </Link>
            </div>
          </div>
        </section>
      </>
    );
}

export default Scrum