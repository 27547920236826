import React from "react";
import Modal from "react-bootstrap/Modal";

function Specialize({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Specialized Staffing</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <p>
          From niche technologies to specific industry requirements, we offer
          specialized staffing services tailored to your unique demands.
        </p>       
      </Modal>
    </>
  );
}

export default Specialize;
