import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import * as Yup from "yup";

const validationSchema = Yup.object({
  user_name: Yup.string().required("User Name is Required"),
  first_name: Yup.string().required("First Name is Required"),
  last_name: Yup.string().required("last Name is Required"),
  mobile: Yup.string().required("Phone No. is Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("emai is Required"),
  role: Yup.string().required("Role is required"),
  password: Yup.string()
    .min(8, "Must be 8 characters or greater")
    .required("Password is Required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must be match")
    .required("Confirm Password is Required"),
});

function AdduserModal({ show, handleClose }) {
  const [imagePreview, setImagePreview] = useState(null);
  const modalStyles = {
    display: show ? "block" : "none",
  };

  const formik = useFormik({
    initialValues: {
      user_name: "",
      first_name: "",
      last_name: "",
      mobile: "",
      email: "",
      role: "",
      password: "",
      image: null,
    },
    validationSchema,
    onSubmit: (values) => {
      axios
        .post("https://agile.digiwbs.com/Admin/api/users", values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          console.log(result);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "User created successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          formik.resetForm();
        })
        .catch((err) => {
          if (err.response) {
            console.error("Error response:", err.response);
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: err.response.data.message || "An error occurred",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            console.error("Error:", err.message);
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "An error occurred",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
    },
  });

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("image", file);
      setImagePreview(URL.createObjectURL(file)); // Create a preview URL
    } else {
      setImagePreview(null); // Clear the preview if no file is selected
    }
  };

  return (
    <>
      <div className="modal" style={modalStyles}>
        <div className="modal-content">
          <div className="modal-head">
            <h2>Add User</h2>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
          </div>
          <hr />
          <form onSubmit={formik.handleSubmit}>           
            <label for="upload" className="profile-pic">
              <input
                type="file"
                accept="\image*"
                id="upload"
                hidden
                onChange={handleImageChange}
              />
              <div className="upload-img">
                <img
                  src={imagePreview}
                  alt="Profile"
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </label>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="First Name*"
                  alt="FirstName"
                  name="first_name"
                  value={formik.values.first_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <p className="err">{formik.errors.first_name}</p>
                )}
              </div>
              <div className="err">
                <input
                  type="text"
                  placeholder="Last Name*"
                  alt="LastName"
                  name="last_name"
                  value={formik.values.last_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <p className="err">{formik.errors.last_name}</p>
                )}
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="User Name*"
                  className="username"
                  name="user_name"
                  value={formik.values.user_name}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.user_name && formik.errors.user_name && (
                  <p className="err">{formik.errors.user_name}</p>
                )}
              </div>
              <div className="err">
                <input
                  type="email"
                  placeholder="Email*"
                  alt="eamil"
                  name="email"
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="err">{formik.errors.email}</p>
                )}
              </div>
            </div>

            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="text"
                  placeholder="PhoneNo*"
                  alt="mobile"
                  name="mobile"
                  value={formik.values.mobile}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <p className="err">{formik.errors.mobile}</p>
                )}
              </div>
              <div className="err">
                <select
                  name="role"
                  value={formik.values.role}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                >
                  <option>Select Roll Type</option>
                  <option value={"superadmin"}>Super Admin</option>
                  <option value={"admin"}>Admin</option>
                  <option value={"employee"}>Employee</option>
                </select>
                {formik.touched.role && formik.errors.role && (
                  <p className="err">{formik.errors.role}</p>
                )}
              </div>
            </div>
            <div className="d-flex gaping">
              <div className="err">
                <input
                  type="password"
                  placeholder="Password*"
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password && (
                  <p className="err">{formik.errors.password}</p>
                )}
              </div>
              <div className="err">
                <input
                  type="password"
                  placeholder="Confirm password*"
                  name="confirm_password"
                  value={formik.values.confirm_password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
                {formik.touched.confirm_password &&
                  formik.errors.confirm_password && (
                    <p className="err">{formik.errors.confirm_password}</p>
                  )}
              </div>
            </div>
            <tabel className="per-table">
              <thead>
                <tr>
                  <th className="modal-cate">Module Permission</th>
                  <th>Read</th>
                  <th>Write</th>
                  <th className="modal-dlt">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="modal-cate">Super Admin</td>
                  <td className="modal-read model-td">
                    <input type="checkbox" checked name="superAdmin-read" />
                  </td>
                  <td className="model-td">
                    <input type="checkbox" checked name="superAdmin-write" />
                  </td>
                  <td className="modal-dlt model-td">
                    <input type="checkbox" checked name="superAdmin-execute" />
                  </td>
                </tr>
                <tr>
                  <td className="modal-cate">Admin</td>
                  <td className="modal-read model-td">
                    <input type="checkbox" checked name="hrAdmin-read" />
                  </td>
                  <td className="model-td">
                    <input type="checkbox" name="hrAdmin-write" />
                  </td>
                  <td className="modal-dlt model-td">
                    <input type="checkbox" name="hrAdmin-execute" />
                  </td>
                </tr>
                <tr>
                  <td className="modal-cate">Employee</td>
                  <td className="modal-read model-td">
                    <input type="checkbox" checked name="employee-read" />
                  </td>
                  <td className="model-td">
                    <input type="checkbox" name="employee-write" />
                  </td>
                  <td className="modal-dlt model-td">
                    <input type="checkbox" name="employee-execute" />
                  </td>
                </tr>
              </tbody>
            </tabel>
            <div className="tablebtn">
              <button className="addbtn" type="submit">
                Add User
              </button>
              <button className="cnclbtn" onClick={handleClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AdduserModal;
