import React from "react";
import Modal from "react-bootstrap/Modal";
function Ongoing({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Professional Cyber Security On-Going Training</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <li>CompTIA Security+ Certification</li>
        <li>CompTIA CySA+ Certification</li>
        <li>CEH Certification</li>
        <li>CISSP Certification</li>
        <li>Custom-Tailored Training as per the business need</li>
        <h2>Training Method:</h2>
        <li>Client’s Site</li>
        <li>Virtual Live Training</li>
        <li>Instructor-Led Training</li>
      </Modal>
    </>
  );
}

export default Ongoing;
