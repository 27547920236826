import React from 'react'
import Modal from "react-bootstrap/Modal";
function Intellgen({ show, handleClose }) {
   
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
          <div className="rpafocusmodalhead">
            <h2>Intelligent Document Processing – Gen AI</h2>
            <span className="close" onClick={handleClose}>
              &times;
            </span>
          </div>
          <p className="rpafocusmodalpara">
            Leveraging cutting-edge technologies such as Intelligent Document
            Processing (IDP) and Gen AI within RPA tools, we empower
            organizations to build a robust digital workforce capable of
            efficiently processing documents containing unstructured data.
            Through pre-programmed rule sets and advanced data extraction
            programming techniques like Natural Language Processing (NLP),
            Optical Character Recognition (OCR), and Machine Learning (ML), our
            solutions enable the extraction of valuable insights from diverse
            document formats. IDP functionalities allow for the scanning and
            collection of information from digital document feeds, which can
            then be seamlessly integrated into automated workflows through the
            execution of bots. This streamlined process enhances efficiency,
            accuracy, and productivity, driving tangible business outcomes.
          </p>
          <br />

          <ol>
            <li>
              Utilization of cutting-edge technologies such as IDP and Gen AI
              within RPA tools
            </li>
            <li>
              Efficient processing of documents containing unstructured data
            </li>
            <li>
              Advanced data extraction programming techniques including NLP,
              OCR, and ML
            </li>
            <li>
              Seamless integration of document processing capabilities into
              automated workflows using bots
            </li>
          </ol>
       </Modal>
    </>
  );
}

export default Intellgen