import expertise from "../../images/image 99.png";
import record from "../../images/image 100.png";
import solution from "../../images/image 101.png";
import scalable from "../../images/image 102.png";
import cost from "../../images/image 103.png";
import inter from "../../images/image 104.png";
import view from "../../images/image 105.png";

function Itstaffchooseus() {
  return (
    <>
      <section className="mtschoose pt-5  pb-5">
        <div className="container">
          <h2>Why Choose Agile Tech Labs for IT Staffing?</h2>
          <div className="row w-100 mts-row">
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={expertise} alt="Expertise" />
                <h3>Expert Talent Pool</h3>
              </div>
              <p>
                With a vast network of IT professionals, we have access to a
                diverse range of experts in various fields, including software
                development, data analytics, cloud computing, cybersecurity, and
                more.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={record} alt="Comprehensive Services" />
                <h3>Tailored Solutions</h3>
              </div>
              <p>
                We work closely with you to understand your specific
                requirements and provide candidates that are not only
                technically proficient but also a cultural fit for your
                organization.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={solution} alt="Cost-Effectiveness" />
                <h3>Quick Turnaround</h3>
              </div>
              <p>
                Our streamlined staffing processes ensure that we can quickly
                identify and place the right candidates, minimizing downtime and
                keeping your projects on track.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={scalable} alt="Focus on Quality" />
                <h3>Flexible Engagement Models</h3>
              </div>
              <p>
                Whether you need a single resource for a short-term project or
                an entire team for long-term initiatives, we offer flexible
                staffing solutions that adapt to your changing needs.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3>Proven Track Record</h3>
              </div>
              <p>
                With years of experience in the industry, Agile Tech Labs has
                successfully placed IT professionals across various sectors,
                helping businesses achieve their goals with the right talent.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={inter} alt="Scalability" />
                <h3>
                  Detailed Interview Reports: Comprehensive Insights with Every
                  Candidate Submission
                </h3>
              </div>
              <p>
                At Agile Tech Labs, we go beyond the standard resume submission
                process. We provide our clients with detailed interview reports
                in PDF format, ensuring you have all the information you need to
                make informed hiring decisions.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={view} alt="Scalability" />
                <h3>What's Included in Our Interview Reports</h3>
              </div>
              <li>
                <strong>Overall Ratings/Score:</strong> Each candidate is
                assessed across various competencies relevant to the role. We
                provide an overall rating or score to give you a quick snapshot
                of their suitability
              </li>
              <li>
                <strong>Strengths and Weaknesses:</strong> Our reports highlight
                the candidate's key strengths and areas for improvement,
                providing you with a balanced view of their potential fit within
                your team.
              </li>
              <li>
                <strong>Interview Transcripts/Assessment Summary: </strong>We
                include detailed transcripts or summaries of the interview or
                assessment process, offering insights into the candidate's
                thought process, problem-solving abilities, and technical
                expertise.
              </li>
              <p>
                This comprehensive approach ensures that you have a clear and
                complete understanding of each candidate's qualifications,
                making your hiring process more efficient and effective.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Itstaffchooseus;
