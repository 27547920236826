import React from 'react'
import { Link } from 'react-router-dom';
import Inotech from './Inotech'

function Robotics() {
    return (
      <>
        <section className="robo ino">
          <div className="container ino-container">
            <div className="heading">
              <h1>Robotic Process Automation</h1>
              <Inotech />
            </div>

            <div className="btngroup d-flex">
              <Link to="/rpa">
                <button className="learn">Learn More</button>
              </Link>
              <Link to="/contact">
                <button className="contact">Contact</button>
              </Link>
            </div>
          </div>
        </section>
      </>
    );
}

export default Robotics