import React, { useState } from "react";
import migrat from "../../images/Legacy System Assessment.jpeg";
import integrat from "../../images/Technology Upgrade.jpeg";
import develop from "../../images/Cloud Migration.jpeg";
import implement from "../../images/User Interface Modernization.jpeg";
import session from "../../images/Data Modernization.jpeg";
import security from "../../images/Security Enhancements.jpeg";
import system from "../../images/System Integration.jpeg";
import performance from "../../images/Performance Optimization.jpeg";
import Legacysystem from "./focus areas/Legacysystem";
import Technologyupgrade from "./focus areas/Technologyupgrade";
import Cloudmigration from "./focus areas/Cloudmigration";
import Userinterface from "./focus areas/Userinterface";
import Datamodern from "./focus areas/Datamodern";
import Systemintegration from "./focus areas/Systemintegration";
import Performance from "./focus areas/Performance";
import Security from "./focus areas/Security";

function Productfocus() {
  const [legacyModal, setLegacyModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [cloudModal, setCloudModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [dataModal, setDataModal] = useState(false);
  const [systemModal, setSystemModal] = useState(false);
  const [performanceModal, setPerformanceModal] = useState(false);
  const [securityModal, setSecurityModal] = useState(false);

  const openModal = () => setLegacyModal(true);
  const closeModal = () => setLegacyModal(false);

  const procesShow = () => setUpgradeModal(true);
  const closeprocess = () => setUpgradeModal(false);

  const cloudShow = () => setCloudModal(true);
  const closestrategy = () => setCloudModal(false);

  const userShow = () => setUserModal(true);
  const closeproject = () => setUserModal(false);

  const dataShow = () => setDataModal(true);
  const closetraining = () => setDataModal(false);

  const systemShow = () => setSystemModal(true);
  const closesystem = () => setSystemModal(false);

  const performanceShow = () => setPerformanceModal(true);
  const closeperformance = () => setPerformanceModal(false);

  const securityShow = () => setSecurityModal(true);
  const closesecurity = () => setSecurityModal(false);
  return (
    <>
      <section className="mtsfocus">
        <div className="container">
          <h2>Focus Areas of Product Re-Engineering </h2>
          <h2>Services at Agile Tech Labs</h2>
          <p>
            At Agile Tech Labs, we offer a range of services to help modernize
            your legacy applications and systems. Our focus areas include:
          </p>
          <div className="imgroup">
            <div className="row">
              <div className="col-12 col-lg-4 " onClick={openModal}>
                {/* <Link to="/rpa"> */}
                <div className="btnimg">
                  <img src={migrat} loading="lazy" alt="migrat" />
                  <div className="mtsfocustxt">
                    <p>Legacy System Assessment</p>
                  </div>
                </div>
                {/* </Link> */}
              </div>
              <div className="col-12 col-lg-4" onClick={procesShow}>
                <div className="btnimg">
                  <img src={integrat} loading="lazy" alt="integrat" />
                  <div className="mtsfocustxt">
                    <p>Technology Upgrades</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={cloudShow}>
                <div className="btnimg">
                  <img src={develop} loading="lazy" alt="develop" />
                  <div className="mtsfocustxt">
                    <p>Cloud Migration</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={userShow}>
                <div className="btnimg">
                  <img src={implement} loading="lazy" alt="implement" />
                  <div className="mtsfocustxt">
                    <p>User Interface (UI) Modernization</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={dataShow}>
                <div className="btnimg">
                  <img src={session} loading="lazy" alt="session" />
                  <div className="mtsfocustxt">
                    <p>Data Modernization</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={systemShow}>
                <div className="btnimg">
                  <img src={system} loading="lazy" alt="session" />
                  <div className="mtsfocustxt">
                    <p>System Integration</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={performanceShow}>
                <div className="btnimg">
                  <img src={performance} loading="lazy" alt="session" />
                  <div className="mtsfocustxt">
                    <p>Performance Optimization</p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4" onClick={securityShow}>
                <div className="btnimg">
                  <img src={security} loading="lazy" alt="session" />
                  <div className="mtsfocustxt">
                    <p>Security Enhancements</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <p>
            At Agile Tech Labs, our goal is to make your legacy systems work
            better for your business. We focus on delivering solutions that are
            not only modern and efficient but also tailored to your specific
            needs.
          </p>
        </div>
      </section>
      <Legacysystem show={legacyModal} handleClose={closeModal} />
      <Technologyupgrade show={upgradeModal} handleClose={closeprocess} />
      <Cloudmigration show={cloudModal} handleClose={closestrategy} />
      <Userinterface show={userModal} handleClose={closeproject} />
      <Datamodern show={dataModal} handleClose={closetraining} />
      <Systemintegration show={systemModal} handleClose={closesystem} />
      <Performance show={performanceModal} handleClose={closeperformance} />
      <Security show={securityModal} handleClose={closesecurity} />
    </>
  );
}

export default Productfocus;
