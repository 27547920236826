import React from "react";
import expertise from "../../images/image 106.png";
import record from "../../images/image 107.png";
import solution from "../../images/image 108.png";
import scalable from "../../images/image 109.png";
import cost from "../../images/image 110.png";
import compransive from "../../images/image 111.png";

function Productchooseus() {
  return (
    <>
      <section className="mtschoose pt-5  pb-5">
        <div className="container">
          <h2>Why Choose Agile Tech Labs for Product Re-Engineering?</h2>

          <div className="row w-100 mts-row">
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={expertise} alt="Expertise" />
                <h3>Proven Expertise</h3>
              </div>
              <p>
                With over two decades of experience, Agile Tech Labs has a
                strong track record of successfully modernizing legacy
                applications across various industries. Our team of seasoned
                professionals brings deep technical knowledge and hands-on
                experience to every project.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={record} alt="Comprehensive Services" />
                <h3>Tailored Solutions</h3>
              </div>
              <p>
                We understand that every business is unique. That’s why we take
                the time to assess your specific needs and craft a
                re-engineering strategy that aligns perfectly with your goals,
                ensuring the best possible outcomes.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={solution} alt="Cost-Effectiveness" />
                <h3>Risk Mitigation</h3>
              </div>
              <p>
                Migrating legacy applications can be complex, but our methodical
                approach minimizes risks. We employ rigorous testing and quality
                checks at every stage to ensure a smooth transition with minimal
                disruption to your operations.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={scalable} alt="Focus on Quality" />
                <h3>Cost-Effective</h3>
              </div>
              <p>
                We leverage our repository of reusable components and refined
                delivery processes to offer cost-effective solutions without
                compromising on quality. Our goal is to deliver high-performance
                results while keeping your budget in check.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={cost} alt="Scalability" />
                <h3>Future-Ready</h3>
              </div>
              <p>
                At Agile Tech Labs, we don’t just modernize applications for
                today—we prepare them for tomorrow. Our solutions are designed
                to be scalable and adaptable, so your business can continue to
                grow and evolve with confidence.
              </p>
            </div>
            <div className="mts-content">
              <div className="d-flex align-items-center">
                <img src={compransive} alt="Customer-Centric Approach" />
                <h3>Ongoing Support</h3>
              </div>
              <p>
                Our commitment to your success doesn’t end with the project’s
                completion. We provide comprehensive post-migration support to
                ensure your modernized applications run smoothly and continue to
                deliver value.
              </p>
            </div>
            <p>
              Choosing Agile Tech Labs means partnering with a team that’s
              dedicated to transforming your legacy systems into powerful,
              future-ready tools that drive your business forward.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Productchooseus;
