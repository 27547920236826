import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { getToken } from "../../../utils/helper";
import { Link, useParams } from "react-router-dom";
import Sectionmodal from "./Sectionmodal";
import Swal from "sweetalert2";
import EditSectionModal from "./EditSectionModal";
const baseUrl = process.env.REACT_APP_BASE_URL;

const validationSchema = Yup.object({
  name: Yup.string().required(`Page name is required`),
});
function Homepage() {
  const { id } = useParams();
  // let token = getToken();
  const [data, setData] = useState();
  const [pageData, setPageData] = useState();
  const [editData, setEditData] = useState();
  const [selectedItem, setSelectedItem] = useState("RPA");
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const openModal = (id) => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const openEditModal = (id) => {
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    setShowEditModal(false);
  };
  const fetchSectionData = function () {
    axios
      .get(`${baseUrl}sectionsList/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editSection = (_id) => {
    axios
      .get(`${baseUrl}sectionDetails/${_id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setEditData(result.data.body);
        openEditModal();
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const deleteSection = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // let token = getToken();

        axios
          .get(`${baseUrl}deleteSection/${_id}`, {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${token}`,
            },
          })
          .then((result) => {
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
            });
            fetchSectionData();
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      }
    });
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}PageDetails/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setPageData(result.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
    fetchSectionData();
  }, [id]);
  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const renderContent = () => {
    switch (selectedItem) {
      case "RPA":
        return <p>RPA content goes here.</p>;
      case "QA Automation":
        return <p>QA Automation content here.</p>;
    }
  };

  return (
    <>
      <div className="home-page">
        <h2>{pageData?.name}</h2>
        <div className="home-row">
          <div className=" left-col">
            <button onClick={openModal}>+ Add New Section</button>
            <ul>
              {data &&
                data.map((val) => (
                  <>
                    <li
                      onClick={() => handleItemClick(val.name)}
                      className={selectedItem === val.name ? "activee" : ""}
                    >
                      <div className="d-flex justify-content-between">
                        <span> {val.name}</span>
                        <div className="dropdown">
                          <button
                            className="dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="fa-solid fa-ellipsis-vertical"></i>
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link
                              className="dropdown-item"
                              onClick={() => editSection(val._id)}
                            >
                              <i className="fa fa-pen"></i>Edit
                            </Link>
                            <Link
                              className="dropdown-item"
                              onClick={() => deleteSection(val._id)}
                            >
                              <i className="fa fa-trash"></i>Delete
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </>
                ))}
              {/* <li
                onClick={() => handleItemClick("QA Automation")}
                className={selectedItem === "QA Automation" ? "activee" : ""}
              >
                <div className="d-flex justify-content-between">
                  <span>QA Automation</span>
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        <i className="fa fa-pen"></i>Edit
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="fa fa-trash"></i>Delete
                      </a>
                    </div>
                  </div>
                </div>
              </li> */}
            </ul>
          </div>
          <div className=" right-col">{renderContent()}</div>
        </div>
      </div>
      <Sectionmodal
        show={showModal}
        handleClose={closeModal}
        fetchSectionData={fetchSectionData}
      />
      <EditSectionModal
        show={showEditModal}
        handleClose={closeEditModal}
        fetchSectionData={fetchSectionData}
        editData={editData}
      />
    </>
  );
}

export default Homepage;
