import Modal from "react-bootstrap/Modal";
import React from "react";

function Managetesting({ show, handleClose }) {
  return (
    <>
      <Modal className="modal rpafocusmodal" show={show} onHide={handleClose}>
        <div className="rpafocusmodalhead">
          <h2>Managed Testing Services (a-MTS)</h2>
          <span className="close" onClick={handleClose}>
            &times;
          </span>
        </div>
        <li className="rpafocusmodalpara">
          Agile Techlabs Managed Testing Services is a flexible solution that
          lowers risk, saves money and improves software quality based on Best
          Practices
        </li>
        <li>
          Managed Testing Services combines a structured and proven process with
          the flexibility to mix multiple resource options with many
          infrastructure options.
        </li>
      </Modal>
    </>
  );
}

export default Managetesting;
